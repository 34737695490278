<template>
  <div id="quality-ball" v-if="show">
    <router-link to="/question_feedback">
      <div class="ball" :class="quality"></div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'quality_ball',
  data () {
    return {
       
    }
  },
  props: {
    'quality':{type: String, default: 'black'},
    'show': {type: Boolean, default: true}
  }
}
</script>

<style scoped>
  #quality-ball {
    position: fixed;
    right: .30rem;
    bottom: 2.50rem;
    z-index: 99;
  }
  .ball {
    width: .80rem;
    height: .80rem;
    border-radius: 50%;
    background: rgba(255, 255, 255, .4);
    border: .10rem solid black;
  }
  .ball.black {
    border: .10rem solid black;
  }
  .ball.yellow {
    border: .10rem solid yellow;
  }
  .ball.green {
    border: .10rem solid green;
  }
</style>
