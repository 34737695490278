const orderRouters = [
  {
    path: "/order_list",
    name: "order_list",
    component: resolve => require(["@/views/order/orderList.vue"], resolve),
    meta: {
      title: "我的订单"
    }
  },
  {
    path: "/order_detail",
    name: "order_detail",
    component: resolve => require(["@/views/order/orderDetail.vue"], resolve),
    meta: {
      title: "订单详情"
    }
  },
  {
    path: "/sale-list",
    name: "sale-list",
    component: resolve => require(["@/views/order/saleList.vue"], resolve),
    meta: {
      title: "服务类型"
    }
  },
  {
    path: "/apply-sale",
    name: "apply-sale",
    component: resolve => require(["@/views/order/applySale.vue"], resolve),
    meta: {
      title: "退款申请"
    }
  },
  {
    path: "/apply-success",
    name: "apply-success",
    component: resolve => require(["@/views/order/applySuccess.vue"], resolve),
    meta: {
      title: "申请成功"
    }
  },
  {
    path: "/group-list",
    name: "group-list",
    component: resolve => require(["@/views/goods/groupList.vue"], resolve),
    meta: {
      title: "拼团列表"
    }
  },
  {
    path: "/material-flow",
    name: "material-flow",
    component: resolve => require(["@/views/order/materialFlow.vue"], resolve),
    meta: {
      title: "物流查询"
    }
  },
  {
    path: "/order-collect",
    name: "order-collect",
    component: resolve => require(["@/views/order/orderCollect.vue"], resolve),
    meta: {
      title: "订单汇总"
    }
  },
  {
    path: "/evaluation",
    name: "evaluation",
    component: resolve => require(["@/views/order/evaluation.vue"], resolve),
    meta: {
      title: "评价"
    }
  },
  {
    path: "/evaluation_success",
    name: "evaluation_success",
    component: resolve => require(["@/views/order/evaluationSuccess.vue"], resolve),
    meta: {
      title: "评价"
    }
  },
  {
    path: "/confirm_success",
    name: "confirm_success",
    component: resolve => require(["@/views/order/ConfirmSuccess.vue"], resolve),
    meta: {
      title: "我的订单"
    }
  }
];

export const orderRouter = [...orderRouters];
