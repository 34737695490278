export const seaRouters = [
  {
    path: '/ceremony',
    name: 'ceremony',
    component: resolve => require(["@/views/my/luckyWheel/sea/ceremony.vue"], resolve),
    meta: {
      title: "组队赢好礼"
    }
  },
  {
    path: '/seaIndex',
    name: 'seaIndex',
    component: resolve => require(["@/views/my/luckyWheel/sea/seaIndex.vue"], resolve),
    meta: {
      title: "三重礼"
    }
  },
  {
    path: '/receive_award',
    name: 'receive_award',
    component: resolve => require(["@/views/my/luckyWheel/sea/receiveAward.vue"], resolve),
    meta: {
      title: "领取奖励"
    }
  },
  {
    path: '/transit',
    name: 'transit',
    component: resolve => require(["@/views/my/luckyWheel/sea/transitPage.vue"], resolve),
    meta: {
      title: "跳转确认"
    }
  },
]

export const seaRouter = [...seaRouters];