import { baseUrl } from "./env";

/**
 * 存储localStorage
 */
export const setStore = (name, content) => {
  if (!name) return;
  if (typeof content !== "string") {
    content = JSON.stringify(content);
  }
  window.localStorage.setItem(name, content);
};
/**
 * 获取localStorage
 */
export const getStore = name => {
  if (!name) return;
  return window.localStorage.getItem(name);
};

/**
 * 删除localStorage
 */
export const removeStore = name => {
  if (!name) return;
  window.localStorage.removeItem(name);
};

/**
 * 获取style样式
 */
export const getStyle = (element, attr, NumberMode = "int") => {
  let target;
  // scrollTop 获取方式不同，没有它不属于style，而且只有document.body才能用
  if (attr === "scrollTop") {
    target = element.scrollTop;
  } else if (element.currentStyle) {
    target = element.currentStyle[attr];
  } else {
    target = document.defaultView.getComputedStyle(element, null)[attr];
  }
  //在获取 opactiy 时需要获取小数 parseFloat
  return NumberMode == "float" ? parseFloat(target) : parseInt(target);
};

/**
 * 时间戳转化
 */
export function formatDate(date, fmt) {
  // console.log(date,fmt)
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  let o = {
    "M+": date.getMonth() + 1,
    "d+": date.getDate(),
    "h+": date.getHours(),
    "m+": date.getMinutes(),
    "s+": date.getSeconds()
  };
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + "";
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? str : padLeftZero(str)
      );
    }
  }
  return fmt;
}

function padLeftZero(str) {
  return ("00" + str).substr(str.length);
}

export const toBool = val => {
  if (val == "false") {
    return false;
  } else if (val == "true") {
    return true;
  }
};

export const deepClone = obj => {
  var _obj = JSON.stringify(obj),
    objClone = JSON.parse(_obj);
  return objClone;
};
/**
 * 判断是否 微信浏览器
 * @returns {boolean}
 */
export const is_weChat = () => {
  let browserName = navigator.userAgent.toLowerCase();
  return /(MicroMessenger)/i.test(browserName);
};
// 判断是否 ios
export const is_ios = () => {
  let browserName = navigator.userAgent.toLowerCase();
  return /(iphone|ipod|ipad)/i.test(browserName);
};
// 判断是否 安卓
export const is_android = () => {
  let browserName = navigator.userAgent.toLowerCase();
  return /(android)/i.test(browserName);
};

/**
 * 获取路由参数
 */
export const getUrlKey = name => {
  return (
    decodeURIComponent(
      (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
        location.href
      ) || [, ""])[1].replace(/\+/g, "%20")
    ) || null
  );
};

//判断是否为微信浏览器
export const isWeiXin = () => {
  let ua = window.navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == "micromessenger") {
    return true;
  } else {
    return false;
  }
};

//处理js小数计算引起的精度误差问题
/* export class Count {
	add (num1, num2) {
		let r1, r2, m;
		try{
			r1 = num1.toString().split('.')[1].length;
		}catch(e){
			r1 = 0
		}
		try{
			r2 = num2.toString().split('.')[1].length;
		}catch(e){
			r2 = 0
		}
		m = Math.pow(10, Math.max(r1, r2));
		return ((num1 * m + num2 * m) / m).toFixed(2);
	}

	subtr (num1, num2) {
		let r1, r2, m, n;
		try{
			r1 = num1.toString().split('.')[1].length;
		}catch(e){
			r1 = 0
		}
		try{
			r2 = num2.toString().split('.')[1].length;
		}catch(e){
			r2 = 0
		}
		m = Math.pow(10, Math.max(r1, r2));
		//动态控制长度
		n = (r1 >= r2) ? r1 : r2;
		 return ((num1 * m - num2 * m) / m).toFixed(n);
	}

	mul (num1, num2) {
		let m = 0,
			s1 = num1.toString(),
			s2 = num2.toString();
		try{ m += s1.split(".")[1].length }catch(e){}
		try{ m += s2.split(".")[1].length }catch(e){}
		return (Number(s1.replace(".","")) * Number(s2.replace(".","")) / Math.pow(10,m)).toFixed(2);
	}

	divide (num1, num2) {
		let t1 = 0,
			t2 = 0,
			r1, r2;
		try{ t1 = num1.toString().split(".")[1].length }catch(e){}
		try{ t2 = num2.toString().split(".")[1].length }catch(e){}
		r1 = Number(num1.toString().replace('.', ''));
		r2 = Number(num2.toString().replace('.', ''));
		return (r1 / r2) * Math.pow(10, t2 - t1);
	}
}; */

//获取小数点后第三位
export const getPointNum = num => {
  let str = String(num);
  let index = str.indexOf(".");
  let str1 = str.slice(index + 3, index + 4);
  str1 = Number(str1);
  return str1;
};

// 积分到现金保留2位小数
export const toCash = price => {
	const str = String(price),
		index = str.indexOf('.'),
		// len = str.split('.')[1].length,
    str1 = str.slice(index + 3, index + 4);
    // console.log('we4', typeof str, str)
	const len = str === 'NaN' ? '' : str.split('.')[1].length  //  处理price传过来NaN
	// 判断小数后是否超过2位	
	if (len > 2) {
		price = Math.ceil(price * 100);
	} else{
		price = price * 100;
	}
	return price / 100;
};

//微信分享公用方法
export const wxShare = () => {
  wx.config({
    // debug: true,
    debug: false,
    appId: appId,
    timestamp: timestamp,
    nonceStr: nonceStr,
    signature: signature,
    jsApiList: [
      "checkJsApi",
      // 'onMenuShareTimeline',//旧的接口，即将废弃
      // 'onMenuShareAppMessage',//旧的接口，即将废弃
      "updateAppMessageShareData",
      "updateTimelineShareData",
      "onMenuShareQQ",
      "onMenuShareWeibo",
      "chooseImage",
      "uploadImage",
      "previewImage",
      "openLocation",
      "chooseWXPay"
    ]
  });

  wx.ready(function() {
    // 1 判断当前版本是否支持指定 JS 接口，支持批量判断
    wx.checkJsApi({
      jsApiList: [
        "getNetworkType",
        "previewImage",
        "onMenuShareTimeline",
        "onMenuShareAppMessage",
        "updateAppMessageShareData",
        "updateTimelineShareData",
        "onMenuShareQQ",
        "chooseImage",
        "uploadImage",
        "onMenuShareWeibo",
        "chooseWXPay"
      ],
      success: function(res) {
        // console.log('support: ' + JSON.stringify(res));
      }
    });
    let homepage = window.localStorage.getItem("getHomePath");
    let bid = window.localStorage.getItem("bid") || "";
    let link = "";
    let m_img = "http://yiqitong.oss-cn-hangzhou.aliyuncs.com/Uploads/image/2020-05/20200521150849_%E6%9C%AA%E6%A0%87%E9%A2%98-1.png";
    let businessInfo = getStore('businessInfo');
		const display_only = businessInfo && businessInfo.mall_display_only ? JSON.parse(businessInfo.mall_display_only) : 0;
    homepage.indexOf("bid") < 0
      ? (link = homepage.replace("#", `&bid=${bid}&display_only=${display_only}#`))
      : (link = homepage);
    //console.log('title = ' + title + ', desc = ' + desc + ', link = ' + link + ', img = ' + img + ', appId = ' + appId + ', timestamp = ' + timestamp + ', nonceStr = ' + nonceStr + ', signature = ' + signature);
    var shareData = {
      title: window.localStorage.getItem("getShareTitle"),
      desc:
        window.localStorage.getItem("share_text") ||
        "我发现了一个很棒的商城，分享给你说不定你也喜欢",
      link: link,
      imgUrl: window.localStorage.getItem("getShareImg")||m_img,
      trigger: function(res) {
        // alert('用户点击发送给朋友');
      },
      success: function(res) {
        // alert('已分享');
      },
      cancel: function(res) {
        // alert('已取消');
      },
      fail: function(res) {
        // alert('call fail: ' + JSON.stringify(res));
      }
    };
    wx.onMenuShareAppMessage(shareData);
    wx.onMenuShareTimeline(shareData);
    wx.updateAppMessageShareData(shareData);
    wx.updateTimelineShareData(shareData);
    wx.onMenuShareQQ(shareData);
    wx.onMenuShareWeibo(shareData);
  });
};

/*
** randomWord 产生任意长度随机字母数字组合
** randomFlag-是否任意长度 min-任意长度最小位[固定位数] max-任意长度最大位
** xuanfeng 2014-08-28
*/
export const randomWord = (randomFlag, min, max) => {
	let str = "",
		range = min,
		arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

	// 随机产生
	if(randomFlag){
		range = Math.round(Math.random() * (max-min)) + min;
	}
	for(let i = 0; i < range; i++){
		const pos = Math.round(Math.random() * (arr.length-1));
		str += arr[pos];
	}
	return str;
}