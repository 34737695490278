// import Vue from "vue";
import Vuex from "vuex";
import router from "./router";

import {
  setStore,
  getStore,
  removeStore,
  is_ios,
  is_android,
  isWeiXin
} from "./utils/common";
import {
  getSystemUserInfo,
  getUserAddressDetail,
  getBusinessRate,
  getAliOssConfigInfo,
} from "@/services/my";
import { getColor } from "@/services";
import { getB3Config } from "@/services/user";
import { Toast, Confirm } from "vue-yrui/dist/lib.rem/dialog";
import { customAccount, CustomShop } from "@/utils/custom";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    session_id: getStore("session_id") || "", // session_id
    userInfo: getStore("userInfo") ? JSON.parse(getStore("userInfo")) : {}, // 用户信息
    default_address: getStore("default_address")
      ? JSON.parse(getStore("default_address"))
      : {}, // 默认收货地址信息
    businessInfo: getStore("businessInfo")
      ? JSON.parse(getStore("businessInfo"))
      : {}, // 积分比例和商城类型信息
    config: {}, // b3配置项
    address: "", //收货地址
    order_note: "", //订单备注
    activedAddress: {}, // 商品详情中选择的配送地址
    goodsInfo: {
      postage: "--",
      stock_name: "--"
    }, // 判断商品是否有货，是否包邮
    create_order: {},
    settle_data: getStore("settle_data")
      ? JSON.parse(getStore("settle_data"))
      : {}, // 直接购买时结算相关参数
    exchange_data: getStore("exchange_data")
      ? JSON.parse(getStore("exchange_data"))
      : {}, // 兑换商品时结算相关参数
    search_where: "",
    card_data: {}, // 卡密相关
    subject_id: "", //专区id
    category_id: "", //分类id
    generalize_id: "", //推广人id
    is_show: "",
    nickname: "",
    business_id: "",
    code: "", //图形验证码
    moveTop: 0, //卡册详情返回时得fullage第几屏
    displayPrice: "0",
    seaStatus: false, //  海元素定制商城，首页涉及到了一个加载问题，就写在vuex中
    tripartite_type: getStore('tripartite_type') ? getStore('tripartite_type') : ""  // 三方商城类型
  },
  mutations: {
    //获取注册用户推广扫码信息
    getUserRegister(state, item) {
      // console.log(state,item,'item')
      state.generalize_id = item.generalize_id;
      state.code = item.code || "";
      state.business_id = item.business_id;
      state.is_show = item.is_show;
      state.nickname = item.nickname || "";
    },
    //获取用户专区分类id
    getParameterId(state, item) {
      state.subject_id = item.subject_id || "";
      state.category_id = item.category_id || "";
    },
    // 获取用户session_id
    getSessionId(state, info) {
      state.session_id = info;
      setStore("session_id", info);
    },
    // 获取用户信息
    getUser(state, info) {
      state.userInfo = info;
      // console.log('test')
      if (state.userInfo.id) {
        state.config.price_status = "1";
      }
      setStore("userInfo", info);
    },
    //删除用户信息
    clearUserInfo(state) {
      state.userInfo = {};
      removeStore("userInfo");
    },
    //获取默认地址信息
    getDefaultAddress(state, info) {
      state.default_address = info;
      setStore("default_address", info);
    },
    //删除默认地址
    clearAddress(state) {
      state.default_address = "";
      removeStore("default_address");
    },
    //获取积分比例和商城类型
    getBusiness(state, info) {
      state.businessInfo = info;
      setStore("businessInfo", info);
			
      const Custom = new CustomShop(info.id);
			setStore('tripartite_type', Custom.backName());
      state.tripartite_type = Custom.backName();

      if (customAccount("VUE_APP_POINTS_TOPIC")) {
        state.seaStatus = true;
      }
      // console.log('sea', state.seaStatus)
    },

    // 获取系统配置
    setConfig(state, info) {
      // console.log('displayPrice1', sessionStorage.getItem('displayPrice'))
      state.config = info;
      if (!state.userInfo.id && parseInt(state.config.price_status)) {
        sessionStorage.setItem("displayPrice", "1");
        // console.log('displayPrice2', sessionStorage.getItem('displayPrice'))
      }
      if (state.userInfo.id) {
        if (parseInt(state.config.price_status)) {
          state.config.price_status = "1";
          sessionStorage.setItem("displayPrice", "1");
        } else {
          state.config.price_status = "1";
          sessionStorage.setItem("displayPrice", "0");
        }
      }
      setStore("config", info);
    },
    // 清空所有缓存数据
    clear(state) {
      state.userInfo = {};
      state.default_address = {};
      if (sessionStorage.getItem("displayPrice") !== "1") {
        state.config.price_status = "0";
      }
      //state.config = {};
      //localStorage.clear();
    },
    getCreateOrder(state, info) {
      state.create_order = {
        ...state.create_order,
        ...info
      };
    },
    //搜索参数
    getSearchWhere(state, info) {
      state.search_where = info;
    },
    // 清空搜索参数
    clearSearchWhere(state) {
      state.search_where = "";
    },
    clearCreateOrder(state) {
      state.create_order = {};
    },
    setAddress(state, activedAddress) {
      state.activedAddress = activedAddress;
    },
    setGoodsInfo(state, goodsInfo) {
      state.goodsInfo = goodsInfo;
    },
    setSettleData(state, settle_data) {
      state.settle_data = settle_data;
      setStore("settle_data", settle_data);
    },
    setExchangeData(state, exchange_data) {
      state.exchange_data = exchange_data;
      setStore("exchange_data", exchange_data);
    },
    // change card_data
    setCardData(state, data) {
      state.card_data = data;
      setStore("card_data", data);
    },
    changeMoveTop(state, moveTop) {
      state.moveTop = 0;
      state.moveTop = moveTop;
    },

    //  修改大地保险签到状态
    changeSignStatus(state, sign) {
      state.userInfo.is_signed_today = sign;
    },

    //  大地保险签到改变积分
    changePoint(state, amount) {
      state.userInfo.integral = amount;
      setStore("userInfo", state.userInfo);
    },

    //  大地保险签到改变积分
    addPoint(state, amount) {
      state.userInfo.integral =
        Number(state.userInfo.integral) + Number(amount);
      setStore("userInfo", state.userInfo);
    },

    cutPoint(state, amount) {
      state.userInfo.integral =
        Number(state.userInfo.integral) - Number(amount);
      setStore("userInfo", state.userInfo);
    },

    setUseableAmount(state, useableAmount) {
      state.cwUseableAmount = useableAmount;
      setStore("useableAmount", useableAmount)
    }
  },
  actions: {
    /**
     * 订单选择信息
     * @param commit
     * @param state
     * @param info
     */
    addOrderInfo({ commit, state }, info) {
      commit("getCreateOrder", {
        ...info
      });
    },
    /**
     * 选择的地址
     * @param commit
     * @param state
     * @param address
     */
    chooseAddress({ commit, state }, address) {
      commit("getCreateOrder", {
        address: address
      });
    },
    /**
     * 获取用户信息
     * @param commit
     * @param state
     * @returns {Promise<void>}
     */
    async getUserInfo({ commit, state, dispatch }) {
      try {
        const res = await getSystemUserInfo();
        if (res.code === 0) {
          const data = res.data;
          commit("getUser", data);

          if (data.user_address_id)
            dispatch("getUserAddressDetail", data.user_address_id);
          // if (data.business_id) dispatch("getThemeColor");
        } else {
          commit("clearUserInfo");
          commit("clear");
          if (state.seaStatus) {
            // this.$dialog.toast({
            //   mes: res.msg,
            // });
            Confirm({
              title: "提示",
              mes: res.msg,
              opts: [
                {
                  txt: "确定",
                  color: "#31BDDF",
                  callback: async () => {
                      
                    if (isWeiXin()) {
                      router.push("login");
                    } else {
                      if (is_android()) {
                        window.jsInterface.close();
                      } else if (is_ios()) {
                        window.webkit.messageHandlers.close.postMessage("");
                      }
                    }
                  }
                }
              ]
            });
          }
        }
      } catch (e) {
        throw e;
      }
    },
    /**
     * 获取超威用户信息
     * @param {*} commit
     * @param {*} state
     * @returns {Promise<void>}
     */
    async getCWUserInfo({ commit }, token) {
      try {
        const res = await getCWUserInfo(token);
        if (res.code === 0) {
          const data = res.data;
          commit("setUserId", data.userId);
          commit("setUserName", data.userName);
          commit("setUsedAmount", data.usedAmount);
          commit("setTotalAmount", data.totalAmount);
          commit("setUseableAmount", data.useableAmount);
        }
      } catch (e) {
        throw e;
      }
    },
    /**
     * 获取默认收货地址详情
     * @param commit
     * @param state
     * @returns {Promise<void>}
     */
    async getUserAddressDetail({ commit, state }, id) {
      try {
        const res = await getUserAddressDetail(id);
        if (res.code === 0) {
          const data = res.data;
          commit("getDefaultAddress", data);
        }
      } catch (e) {
        throw e;
      }
    },
    /**
     * 获取积分比例和商城类型
     * @param commit
     * @param state
     * @returns {Promise<void>}
     */
    async getBusinessRate({ commit, state, dispatch }) {
      try {
        const res = await getBusinessRate();
        if (res.code === 0) {
          const data = res.data;
          commit("getBusiness", data);
          /* if (data.type !== 1 && JSON.stringify(state.userInfo )=== '{}') {
          if ( JSON.stringify(state.userInfo )=== '{}') {
            router.push('login');
          }else {
            dispatch('getUserInfo')
          } */
          dispatch("getConfig");
          /* if (data.type != 3) {
            dispatch("getConfig");
          } */
          return data;
        } else {
          router.push("login");
        }
      } catch (e) {
        throw e;
      }
    },
    // 获取主题色
    async getThemeColor({ state }) {
      try {
        const res = await getColor(state.userInfo.business_id);
        if (res.code === 0) {
          const { color } = res.data;
          document.body.style.setProperty("--main-color", color);
        } else {
          this.$dialog.toast({
            mes: res.msg,
            timeout: 1500,
            icon: "error"
          });
        }
      } catch (error) {
        console.warn("Something bad happened: ", error);
      }
    },
    // 获取b3配置项
    async getConfig({ commit }) {
      try {
        const res = await getB3Config();
        if (res.code === 0) {
          const config = res.data;
          if (config) {
            commit("setConfig", config);
          }
        } else {
          this.$dialog.toast({
            mes: res.msg,
            timeout: 1500,
            icon: "error"
          });
        }
      } catch (error) {
        console.warn("Something bad happened: ", error);
      }
    },

    // 签到改变状态
    changeSignStatus({ commit }, sign) {
      commit("changeSignStatus", sign);
    },

    // 签到改变积分
    changePoint({ commit }, amount) {
      commit("changePoint", amount);
    },

    addPoint({ commit }, amount) {
      commit("addPoint", amount);
    },

    changePoint({ commit }, amount) {
      commit("changePoint", amount);
    }
  },
  getters: {
    moveTop: state => state.moveTop
  }
});
