// import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import "./utils/flexible";
import FastClick from "fastclick";
// import VConsole from "vconsole";
import "./static/css/reset.css";
import "./static/css/normalize.css";
import "./static/css/stylesheet.css";
import "./common/less/base.less";
import "vue-yrui/dist/ydui.base.css";
import "./assets/iconfont/iconfont.js";
import YRUI from "vue-yrui";
// import "vue-yrui/dist/ydui.rem.css";
import cardMallRouter from "./cardMallRouter";
import { getBusinessRate } from "@/services/my";
import { zjgKeepAlive } from "@/utils/custom.js";
import { getStore, getUrlKey } from "@/utils/common";
import VueLazyload from "vue-lazyload";

const loadingImg = require("./static/image/loading_320x320.gif");
Vue.use(VueLazyload, {
  preLoad: 1.1,
  loading: loadingImg,
  attempt: 1,
});

Vue.use(YRUI);
import {
  Confirm,
  Alert,
  Toast,
  Notify,
  Loading,
} from "vue-yrui/dist/lib.rem/dialog";
import IconSvg from "./components/IconSvg";
//全局注册icon-svg
Vue.component("icon-svg", IconSvg);
Vue.prototype.$dialog = {
  confirm: Confirm,
  alert: Alert,
  toast: Toast,
  notify: Notify,
  loading: Loading,
};

if ("addEventListener" in document) {
  document.addEventListener(
    "DOMContentLoaded",
    function () {
      FastClick.attach(document.body);
    },
    false
  );
}

// 解决 :active 在部分浏览器不起作用的 bug, PS:用 :active 可以实现移动端的模拟点击效果相当于pc端的 :hover
document.body.addEventListener("touchstart", function () {});

// 调试小工具
if (
  process.env.NODE_ENV === "development" ||
  process.env.NODE_ENV === "dev" ||
  process.env.NODE_ENV === "localhost"
) {
  new VConsole();
}
document.body.addEventListener("focusout", () => {
  // console.log('键盘收起')
  window.scrollBy(0, 0);
});
// 通用过滤器
import filters from "./filters";

Object.keys(filters).forEach((k) => Vue.filter(k, filters[k]));

Vue.config.productionTip = false;

FastClick.prototype.focus = function (targetElement) {
  let length;
  if (
    targetElement.setSelectionRange &&
    targetElement.type.indexOf("date") !== 0 &&
    targetElement.type !== "time" &&
    targetElement.type !== "month"
  ) {
    length = targetElement.value.length;
    targetElement.focus();
    targetElement.setSelectionRange(length, length);
  } else {
    targetElement.focus();
  }
};

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

function exportWhiteListFromRouter(router) {
  let res = [];
  for (let item of router) res.push(item.path);
  return res;
}

// 卡册商城白名单
const whiteList = exportWhiteListFromRouter(cardMallRouter);

router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title;
  zjgKeepAlive();
  try {
    let res;
    let type;
    console.log(
      '[ JSON.parse(localStorage.getItem("businessInfo")) ] >',
      JSON.parse(localStorage.getItem("businessInfo"))
    );
    if (JSON.parse(localStorage.getItem("businessInfo")) != null) {
      if (
        !JSON.parse(localStorage.getItem("businessInfo")).id ||
        localStorage.getItem("bid") !== getUrlKey("bid")
      ) {
        res = await getBusinessRate();
        type = res.data.type;
      } else {
        res = JSON.parse(localStorage.getItem("businessInfo"));
        type = res.type;
      }
    }
    // if (res.code === 0) {

    if (type === 4) {
      if (whiteList.indexOf(to.path) !== -1) {
        next();
      } else {
        next("/card_mall");
      }
    } else {
      next();
    }
    // }
  } catch (error) {
    console.warn("Something bad happened: ", error);
  }
});
