import { getStore, is_ios, is_android } from "./common";
import store from "../store.js";
// const id = JSON.parse(getStore("businessInfo"))?JSON.parse(getStore("businessInfo")).id:''

export const tripartite = (type) => {
  const customId = process.env[type],
    id = JSON.parse(getStore("businessInfo")).id;

  const customIdList = customId ? customId.split(",") : [];
  let customStatus = false;
  for (let val of customIdList) {
    if (val == id) {
      customStatus = true;
      break;
    }
  }
  return customStatus;
};

export const isfk = (type) => {
  let fklist = process.env["VUE_APP_FK"]
    ? process.env["VUE_APP_FK"].split(",")
    : [];
  let fk = false;
  for (let val of fklist) {
    if (val == JSON.parse(getStore("businessInfo")).id) {
      fk = true;
      break;
    }
  }
  return fk;
};

export const customAccount = (type) => {
  const accountId = JSON.parse(process.env[type]);
  const id = JSON.parse(getStore("businessInfo")).id;
  let accountStatus = false;
  if (accountId.indexOf(id) !== -1) {
    accountStatus = true;
  }
  return accountStatus;
};

const shopData = [
  {
    name: "VUE_APP_ZJG",
    ids: JSON.parse(
      process.env["VUE_APP_ZJG"] ? process.env["VUE_APP_ZJG"] : ""
    ),
    pay_type: 16,
  },
  {
    name: "VUE_APP_ZHONGHANG",
    ids: JSON.parse(
      process.env["VUE_APP_ZHONGHANG"] ? process.env["VUE_APP_ZHONGHANG"] : ""
    ),
    pay_type: "21",
  },
  {
    name: "VUE_APP_JY",
    ids: JSON.parse(process.env["VUE_APP_JY"] ? process.env["VUE_APP_JY"] : ""),
    pay_type: "",
  },
  {
    name: "VUE_APP_CHAOWEI",
    ids: JSON.parse(
      process.env["VUE_APP_CHAOWEI"] ? process.env["VUE_APP_CHAOWEI"] : ""
    ),
    pay_type: "",
  },
];

const eachData = function (id, type) {
  for (let item of shopData) {
    for (let val of item.ids) {
      if (id === val) {
        return item[type];
        break;
      }
    }
  }
};

export class CustomShop {
  constructor(id) {
    this.id = id;
  }

  backName() {
    return eachData(this.id, "name");
  }
}
// export const Custom = new CustomShop(JSON.parse(getStore("businessInfo")) ? JSON.parse(getStore("businessInfo")).id : '');

// 张家港跳转
export const zjgInteraction = () => {
  const type = JSON.parse(getStore("businessInfo")).zrc_bank_utype;

  switch (type) {
    case 0:
      if (is_ios()) {
        window.webkit.messageHandlers.JavaScriptCall_GoLogin.postMessage(null);
        return;
      }
      if (is_android()) {
        window.AndroidNative.JavaScriptCall_GoLogin();
        return;
      }
      break;
    case 1:
      if (is_ios()) {
        window.webkit.messageHandlers.JavaScriptCall_BindCard.postMessage(null);
        return;
      }
      if (is_android()) {
        window.AndroidNative.JavaScriptCall_BindCard();
        return;
      }
      break;
    default:
      break;
  }
};

// 张家港保持会话
export const zjgKeepAlive = () => {
  const info = JSON.parse(getStore("businessInfo")),
    showOnly = info ? info.mall_display_only : 0;
  if (store.state.tripartite_type === "VUE_APP_ZJG" && showOnly !== 1) {
    if (is_ios()) {
      // window.webkit.messageHandlers.JavaScriptCall_KeepAlive.postMessage(null);
      return;
    }
    if (is_android()) {
      window.AndroidNative.JavaScriptCall_KeepAlive();
      return;
    }
    // console.log('保持会话');
  }
};

// 定制商城返回pay_type（需要根据商城id直接确定pay_type时可调用该方法）
export const tripartitePayType = (id) => {
  const shop = [
    {
      name: "zjg",
      ids: JSON.parse(process.env["VUE_APP_ZJG"]),
      pay_type: 16,
    },
    {
      name: "zjg2",
      ids: [1, 2, 3],
      pay_type: 16,
    },
  ];

  for (let item of shop) {
    for (let val of item.ids) {
      if (id === val) {
        return item.pay_type;
        break;
      }
    }
  }
};

// 定制商城支付回调
const payObj = {
  // 工银支付回调
  2(obj, res) {
    obj.icbcHtml = res.data.split("<script>")[0];
    obj.$nextTick(() => {
      document.forms[0].submit();
    });
  },

  // 昆山农商行支付回调
  10(res) {
    if (process.env.NODE_ENV === "intergal") {
      window.location.href = `${process.env.VUE_APP_KSFENQI}${res.data.trade_no}`;
    } else {
      window.location.href = `${process.env.VUE_APP_KUNSHANURL}${res.data.trade_no}`;
    }
  },

  // 拓本支付回调
  13(res) {
    window.location.href = res.data.url;
  },

  // 百兑通支付回调
  15(obj) {
    obj.$router.replace({
      name: "pay_success",
      query: {
        id: obj.pay_order_id,
        money: obj.together_price,
        group_id: obj.group_id ? obj.group_id : "",
      },
    });
  },

  // 张家港农商行支付回调
  16(id, url) {
    const baseUrl = process.env.VUE_APP_BASE_API;
    // console.log(`${baseUrl}/index/Order/payPendingZrcBank?pay_order_id=${id}`);
    // console.log(window.location.href);
    if (is_ios()) {
      window.webkit.messageHandlers.JavaScriptCall_CheckoutCounter.postMessage({
        successUrl: `${baseUrl}/index/Order/payPendingZrcBank?pay_order_id=${id}`,
        startPage: url,
      });
      return;
    }
    if (is_android()) {
      window.AndroidNative.JavaScriptCall_CheckoutCounter(
        url,
        `${baseUrl}/index/Order/payPendingZrcBank?pay_order_id=${id}`
      );
      return;
    }
  },

  ""() {
    console.log(121312);
  },
};
export const payBack = (type, ...res) => {
  return payObj[type](...res);
};

// 中行客户端环境判断
export const terminal = () => {
  let u = navigator.userAgent; //app = navigator.appVersion;
  return {
    //浏览器版本信息
    trident: u.indexOf("Trident") > -1,
    presto: u.indexOf("Presto") > -1,
    webKit: u.indexOf("AppleWebKit") > -1,
    gecko: u.indexOf("Gecko") > -1 && u.indexOf("KHTML") > -1,
    mobile: !!u.match(/AppleWebKit.*Mobile.*/),
    ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
    android: u.indexOf("Android") > -1 || u.indexOf("Linux") > -1,
    iPhone: u.indexOf("iPhone") > -1,
    iPad: u.indexOf("iPad") > -1,
    webApp: u.indexOf("Safari") > -1,
    weixin:
      (u.indexOf("MicroMessenger") > -1 &&
        u
          .replace(/.*(MicroMessenger\/[^\s]*).*/, "$1")
          .replace("MicroMessenger/", "")) ||
      false,
    bocApp: u.indexOf("bocapp(") > -1 ? u.match(/bocapp\(.+\)/)[0] : false,
    appVersion:
      u.indexOf("bocapp(") > -1
        ? u.match(/bocapp\(\S+\)/)[0].slice(7, -1)
        : "3.0.0",
  };
};

// 需要在对应页面展示用户余额的定制商城
export const needBalance = ["VUE_APP_ZJG"];
