/*
 * @Author: zwb
 * @Date: 2019-07-10
 * @Last Modified by: zwb
 * @Last Modified time: 2019-07-10
 */
export function JsonSort(jsonData) {
  try {
    let tempJsonObj = {};
    let sdic = Object.keys(jsonData).sort();
    sdic.map((item, index)=>{
      tempJsonObj[item] = jsonData[sdic[index]]
    })
    return tempJsonObj;
  } catch(e) {
    return jsonData;
  }
}
