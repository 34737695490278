// 接口请求服务，用于获取数据

import fetch from "../utils/fetch";

//// 首页模块
////// 6.1 获取首页模块排序
export const getModules = business_id =>
  fetch("/api", {
    api_name: "V1.index.Index.index",
    business_id: business_id
  });

////// 6.2 获取轮播图数据
export const getSlider = business_id =>
  fetch("/api", {
    api_name: "V1.index.Index.slider",
    business_id: business_id
  });

////// 6.3 获取专题/专区数据
export const getSubject = (business_id, cat) =>
  fetch("/api", {
    api_name: "V1.index.Index.subject",
    business_id: business_id,
    cat: cat
  });

////// 6.4 获取广告图片
export const getCoupon = business_id =>
  fetch("/api", {
    api_name: "V1.index.Index.advice",
    business_id: business_id
  });

////// 6.5 获取商城公告
export const getNotice = business_id =>
  fetch("/api", {
    api_name: "V1.index.Index.notice",
    business_id: business_id
  });

////// 6.6 获取秒杀数据
export const getSeconds = business_id =>
  fetch("/api", {
    api_name: "V1.index.Index.seconds",
    business_id: business_id
  });

////// 6.7 获取团购数据
export const getGroup = business_id =>
  fetch("/api", {
    api_name: "V1.index.Index.groupBuy",
    business_id: business_id
  });

////// 6.8 获取商品专题
export const getItems = business_id =>
  fetch("/api", {
    api_name: "V1.index.Index.items",
    business_id: business_id
  });
//获取b3后台配置分享描述
export const getShareText = () =>
  fetch("/api/getShareText", {
    api_name: "V1.index.index.getShareText"
  });

////// 3、获取拼团商品列表01-06 (完成）
export const getGroupList = (page, listRows) =>
  fetch("/api", {
    api_name: "V1.item.item.getGroupBuyItemList",
    page: page,
    listRows: listRows
  });

////// 5、获取某个开团用户列表中的参团详情01-06-03 (完成)
export const getGroupUserList = group_buy_open_id =>
  fetch("/api", {
    api_name: "V1.item.item.getGroupUserList",
    group_buy_open_id: group_buy_open_id
  });

////// 4、获取某团购商品里的开团用户列表01-06-02 (完成)
export const getGroupItemOpenList = (page, listRows, group_buy_item_id) =>
  fetch("/api", {
    api_name: "V1.item.item.getGroupItemOpenList",
    page: page,
    listRows: listRows,
    group_buy_item_id: group_buy_item_id
  });

////// 2、获取抢购商品列表01-05 (完成)
export const getLimitBuyItemList = (page, listRows, limit_buy_id) =>
  fetch("/api", {
    api_name: "V1.item.item.getLimitBuyItemList",
    page: page,
    listRows: listRows,
    limit_buy_id: limit_buy_id
  });

////// 1、获取抢购时间列表01-05 (完成)
export const getLimitBuyTime = () =>
  fetch("/api", {
    api_name: "V1.item.item.getLimitBuyTime"
  });

////// 1、领券中心01-02 (完成)
export const getVouchersList = (page, listRows) =>
  fetch("/api", {
    api_name: "V1.item.item.getVouchersList",
    page: page,
    listRows: listRows
  });

////// 2、领取优惠券01-02 (完成)
export const userVouchers = vouchers_id =>
  fetch("/api", {
    api_name: "V1.item.item.userVouchers",
    vouchers_send_id: vouchers_id
  });

////// 4、商品列表 (完成)
export const getItemList = where =>
  fetch(
    "/api",
    Object.assign(
      {},
      {
        api_name: "V1.item.item.getItemList",
        ...where
      }
    )
  );

////// 9、订单评价列表
export const getEvaluateList = (item_id, page, listRows) =>
  fetch("/api", {
    api_name: "V1.item.evaluate.evaluateList",
    item_id: item_id,
    page: page,
    listRows: listRows
  });

////// 7.26 主题色的修改和查询
export const getColor = business_id =>
  fetch("/api", {
    api_name: "V1.index.Index.themeColor",
    business_id: business_id
  });

////// 6.10 获取公告列表
export const getNoticeList = (business_id, page, listRows) =>
  fetch("/api", {
    api_name: "V1.index.Index.noticeList",
    business_id: business_id,
    page: page,
    listRows: listRows
  });

////// 6.9 获取公告详情
export const getNoticeDetail = id =>
  fetch("/api", {
    api_name: "V1.index.Index.noticeDetail",
    id: id
  });

////// 1.1、登录接口
export const login = (mobile, password) =>
  fetch("/api", {
    api_name: "V1.User.index.login",
    mobile: mobile,
    password: password
  });
////// 1.1、登录接口
export const login2 = (mobile, password) =>
  fetch("/api", {
    api_name: "V1.User.index.login2",
    mobile: mobile,
    password: password
  });
////// 1.1、定制登录接口
export const login3 = data =>
  fetch("/api", {
    api_name: "V1.User.index.loginViaUsername",
    ...data
  });
////// 1.1、定制登录接口
export const login4 = (username, password, type) =>
  fetch("/api", {
    api_name: "V1.User.index.loginViaUsernameHaiyuansu",
    username: username,
    password: password,
    type: type
  });
//手机+验证码登录
export const codeLogin = (mobile, code) =>
  fetch("/api", {
    api_name: "V1.User.index.mobileLogin",
    mobile: mobile,
    code: code
  });
////// 饭卡登录接口
export const kflogin = (mobile, password, comId) =>
  fetch("/api", {
    api_name: "V1.yunkatong.user.login",
    mobile: mobile,
    password: password,
    comId: comId //793
  });
////// 1.2、注册接口
export const register = (
  mobile,
  password,
  password_confirm,
  nickname,
  business_id,
  generalize_id
) =>
  fetch("/api", {
    api_name: "V1.User.index.register",
    mobile: mobile,
    password: password,
    password_confirm: password_confirm,
    nickname: nickname,
    business_id,
    generalize_id
  });
////// 1.2、注册接口
export const register2 = (
  mobile,
  password,
  password_confirm,
  nickname,
  business_id,
  generalize_id
) =>
  fetch("/api", {
    api_name: "V1.User.index.register2",
    mobile: mobile,
    password: password,
    password_confirm: password_confirm,
    nickname,
    business_id,
    generalize_id
  });
////// 1.2、获取推广信息接口
export const userInfoViaGeneralize = (generalize_code, business_id) =>
  fetch("/api", {
    api_name: "V1.User.index.userInfoViaGeneralize",
    generalize_code,
    business_id
  });
//退出登录
export const logout = (mobile, password, password_confirm) =>
  fetch("/api", {
    api_name: "V1.User.index.logout",
    mobile: mobile,
    password: password,
    password_confirm: password_confirm
  });
//是否已经登录
export const isAuth = () =>
  fetch("/api", {
    api_name: "V1.index.index.isAuth"
  });

////// 1.4、忘记密码
export const forgot = (code, password, password_confirm, mobile) =>
  fetch("/api", {
    api_name: "V1.User.index.forgot",
    code: code,
    password: password,
    password_confirm: password_confirm,
    mobile: mobile
  });

////// 1.5、发送短信
export const sendVerifyCode = (mobile, scene = "login", code) =>
  fetch("/api", {
    api_name: "V1.service.smsApi.send",
    mobile: mobile,
    scene: scene,
    code: code
  });
////// 1.5、发送短信
export const kaceSendsms = (mobile, card_book_id) =>
  fetch("/api", {
    api_name: "V1.service.SmsApi.sendCardBookMsg",
    mobile,
    card_book_id
  });
/**
 * 1.7手机号码验证
 * @param params
 * @returns {Promise<*|*|undefined>}
 */
export const checkMobile = params =>
  fetch("/api", {
    api_name: "V1.service.smsApi.check",
    ...params
  });
////// 1.6、图形验证码
export const getImgCode = generalize_id =>
  fetch("/api", {
    api_name: "V1.service.captchaApi.index",
    generalize_id
  });

//客服接口
export const ServicePhone = () =>
  fetch("/api", {
    api_name: "V1.system.system.getServiceType"
  });

//现金商城跳转积分商城
export const getPointInfo = () =>
  fetch("/api", {
    api_name: "V1.User.Index.getPointInfo"
  });

//积分商城跳转现金商城
export const getCashInfo = () =>
  fetch("/api", {
    api_name: "V1.User.Index.getCashInfo"
  });

/**
 * 登录校验
 * @param params
 * @returns {Promise<*|*|undefined>}
 */
export const cashAndPointLogin = params =>
  fetch("/api", {
    api_name: "V1.User.Index.cashAndPointLogin",
    ...params
  });
/**
 * 联合登陆广大银行
 * @param params
 * @returns {Promise<*|*|undefined>}
 */
export const cebLogin = params =>
  fetch("/api", {
    api_name: "V1.User.Index.cebLogin",
    ...params
  });
/**
 * 联合登陆参数广大银行
 * @param params
 * @returns {Promise<*|*|undefined>}
 */
export const getCebParams = () =>
  fetch("/api", {
    api_name: "V1.User.index.getCebParams"
  });
//个人中心接口
export const getShopInfo = () =>
  fetch("/api", {
    api_name: "V1.index.Index.getShopInfo"
  });
/**
 * 蒙牛活动获取页面配置
 * @param params
 * @returns {Promise<*|*|undefined>}
 */
export const queryActByCode = params =>
  fetch("/api", {
    api_name: "V1.system.system.queryActByCode",
    ...params
  });
/**
 * 蒙牛活动助力
 * @param params
 * @returns {Promise<*|*|undefined>}
 */
 export const partActivity = params =>
 fetch("/api", {
   api_name: "V1.system.system.partActivity",
   ...params
 });