/*
 * @Author: zwb
 * @Date: 2019-05-23
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2019-08-20 20:29:55
 */
import fetch from "../utils/fetch";

/**
 * 获取用户在B3商城中的基本信息
 */
export const getSystemUserInfo = () =>
  fetch("/api", {
    api_name: "V1.User.User.getSystemUserInfo"
  });

/**
 * 获取积分比例和商城类型
 * @returns {Promise<*|*|undefined>}
 */
export const getBusinessRate = () =>
  fetch("/api", {
    api_name: "V1.system.system.getBusinessRate"
  });
/**
 * 获取用户在B3商城中的积分列表
 * @param page
 */
export const getUserIntegralList = params =>
  fetch("/api", {
    api_name: "V1.User.User.getUserIntegralList",
    ...params
  });
  export const getChaoweiIntegral = params =>
  fetch("/api", {
    api_name: "V1.User.User.getChaoweiIntegral",
    ...params
  });
/**
 * 订单角标
 * @returns {Promise<*|*|undefined>}
 */
export const getOrderNumber = () =>
  fetch("/api", {
    api_name: "V1.Order.Order.getOrderNumber"
  });
/**
 * 编辑个人信息
 * @param heagurl
 * @param nickname
 */
export const editUserInfo = (heagurl, nickname, sign) =>
  fetch("/api", {
    api_name: "V1.User.User.editUserInfo",
    heagurl: heagurl,
    nickname: nickname,
    sign: sign
  });
/**
 * 上传文件
 * @returns {Promise<*|*|undefined>}
 */
export const getAliOssConfigInfo = () =>
  fetch("/api", {
    api_name: "V1.system.SystemAdmin.getAliOssConfigInfo"
  });
/**
 *
 * @returns {Promise<*|*|undefined>}
 */
export const getImgCode = generalize_id =>
  fetch("/api", {
    api_name: "V1.service.captchaApi.index",
    generalize_id
  });
/**
 * 原手机号验证
 * @param mobile
 * @param code
 */
export const verifyMobile = (mobile, code) =>
  fetch("/api", {
    api_name: "V1.service.smsApi.check",
    mobile: mobile,
    code: code,
    verify_token: 1
  });
/**
 * 换绑手机号，换绑新手号
 * @param mobile
 * @param code
 */
export const bindNewMobile = (mobile, verify_code) =>
  fetch("/api", {
    api_name: "V1.User.User.bindNewMobile",
    mobile: mobile,
    verify_code: verify_code
  });
export const bindMobile = (mobile, code, password) =>
  fetch("/api", {
    api_name: "V1.User.user.bindMobile",
    mobile: mobile,
    code: code,
    password: password
  });
/**
 * 设置、修改支付密码
 * @param password
 * @param confirm_pass
 * @returns {Promise<*|*|undefined>}
 */
export const editPayPass = (password, confirm_pass,verify_token) =>
  fetch("/api", {
    api_name: "V1.User.User.editPayPass",
    password: password,
    verify_token: verify_token,
    confirm_pass: confirm_pass,
  });
/**
 * 意见反馈
 * @param message
 * @returns {Promise<*|*|undefined>}
 */
export const feedbackSuggest = message =>
  fetch("/api", {
    api_name: "V1.User.User.feedbackSuggest",
    message: message
  });
/**
 * 获取用户收货地址列表
 * @param params
 * @returns {Promise<*|*|undefined>}
 */
export const getUserAddressList = params =>
  fetch("/api", {
    api_name: "V1.User.User.getUserAddressList",
    ...params
  });
/**
 * 用户新增收货地址
 * @param param
 * @returns {Promise<*|*|undefined>}
 */
export const addUserAddress = params =>
  fetch("/api", {
    api_name: "V1.User.User.addUserAddress",
    ...params
  });
/**
 * 根据收货地址id获取收货地址详情
 * @param id
 * @returns {Promise<*|*|undefined>}
 */
export const getUserAddressDetail = id =>
  fetch("/api", {
    api_name: "V1.User.User.getUserAddressDetail",
    id: id
  });
/**
 * 用户编辑收货地址
 * @param param
 * @returns {Promise<*|*|undefined>}
 */
export const editUserAddress = param =>
  fetch("/api", {
    api_name: "V1.User.User.editUserAddress",
    ...param
  });
/**
 * 用户删除收货地址
 * @param id
 * @returns {Promise<*|*|undefined>}
 */
export const deleteUserAddress = id =>
  fetch("/api", {
    api_name: "V1.User.User.deleteUserAddress",
    id: id
  });
/**
 * 获取全国省份
 * @returns {Promise<*|*|undefined>}
 */
export const getProvinceList = () =>
  fetch("/api", {
    api_name: "V1.system.system.getProvinceList"
  });
/**
 * 根据省份获取城市
 * @returns {Promise<*|*|undefined>}
 */
export const getCityList = province_id =>
  fetch("/api", {
    api_name: "V1.system.system.getCityList",
    province_id: province_id
  });
/**
 * 根据城市获取城区
 * @returns {Promise<*|*|undefined>}
 */
export const getAreaList = city_id =>
  fetch("/api", {
    api_name: "V1.system.system.getAreaList",
    city_id: city_id
  });
/**
 * 根据城区获取街道
 * @returns {Promise<*|*|undefined>}
 */
export const getStreetList = area_id =>
  fetch("/api", {
    api_name: "V1.system.system.getStreetList",
    area_id: area_id
  });
/**
 * 幸运大转盘-我的奖品
 * @param params
 * @returns {Promise<*|*|undefined>}
 */
export const getDrawList = params =>
  fetch("/api", {
    api_name: "V1.item.item.getDrawList",
    ...params
  });
/**
 * 幸运大转盘信息
 * @returns {Promise<*|*|undefined>}
 */
export const getTurntableInfo = () =>
  fetch("/api", {
    api_name: "V1.item.item.getTurntableInfo"
  });
/**
 * 抽奖 幸运大转盘
 * @param turntable_id
 * @returns {Promise<*|*|undefined>}
 */
export const luckDraw = turntable_id =>
  fetch("/api", {
    api_name: "V1.item.item.luckDraw",
    turntable_id: turntable_id
  });
/**
 * 获取特殊文章
 * @param article_tag
 * @returns {Promise<*|*|undefined>}
 */
export const getTagArticleDetail = article_tag =>
  fetch("/api", {
    api_name: "V1.article.Article.getTagArticleDetail",
    article_tag: article_tag
  });
/**
 * 根据文章id获取文章详情
 * @param article_id
 * @returns {Promise<*|*|undefined>}
 */
export const getArticleDetail = article_id =>
  fetch("/api", {
    api_name: "V1.article.Article.getArticleDetail",
    article_id: article_id
  });
/**
 * 根据文章栏目获取文章列表
 * @param article_sort_id
 * @param page
 * @param listRows
 * @returns {Promise<*|*|undefined>}
 */
export const getArticleList = params =>
  fetch("/api", {
    api_name: "V1.article.Article.getArticleList",
    ...params
  });
/**
 * 我的优惠券
 * @param params
 * @returns {Promise<*|*|undefined>}
 */
export const getUserVouchersList = params =>
  fetch("/api", {
    api_name: "V1.User.User.getUserVouchersList",
    ...params
  });
/**
 * 我的账户
 * @param params
 * @returns {Promise<*|*|undefined>}
 */
export const getUserAccountList = params =>
  fetch("/api", {
    api_name: "V1.User.User.getUserAccountList",
    ...params
  });
/**
 * 充值
 * @param money
 * @returns {Promise<*|*|undefined>}
 */
export const rechargeAccount = money =>
  fetch("/api", {
    api_name: "V1.Order.recharge.index",
    money: money
  });
/**
 * 卡券验证
 * @param params
 * @returns {Promise<*|*|undefined>}
 */
export const verifyCardTicket = params =>
  fetch("/api", {
    api_name: "V1.User.UserCardTicket.verifyCardTicket",
    ...params
  });
//手机号验证码卡券验证
export const verifyMobileTicket = params =>
  fetch("/api", {
    api_name: "V1.User.UserCardTicket.verifyMobileTicket",
    ...params
  });
/**
 * 卡券充值
 * @param params
 * @returns {Promise<*|*|undefined>}
 */
export const cardTicketRecharge = params =>
  fetch("/api", {
    api_name: "V1.User.UserCardTicket.cardTicketRecharge",
    ...params
  });
/**
 * 获取兑换卡的商品列表
 * @param params
 * @returns {Promise<*|*|undefined>}
 */
export const getExchangeItem = card_id =>
  fetch("/api", {
    api_name: "V1.User.UserCardTicket.getExchangeItem",
    card_id: card_id
  });
/**
 * 采购审批列表
 * @param params
 * @returns {Promise<*|*|undefined>}
 */
export const getExamineList = params =>
  fetch("/api", {
    api_name: "V1.Order.Examine.index",
    ...params
  });
/**
 * 采购审批详情
 * @param params
 * @returns {Promise<*|*|undefined>}
 */
export const getExamineDetail = id =>
  fetch("/api", {
    api_name: "V1.Order.Examine.detail",
    pay_order_id: id
  });
/**
 * 内采商品审核拒绝
 * @param params
 * @returns {Promise<*|*|undefined>}
 */
export const refuseExamine = params =>
  fetch("/api", {
    api_name: "V1.Order.Examine.refuse",
    ...params
  });
/**
 * 内采商品审核通过
 * @param pay_order_id
 * @returns {Promise<*|*|undefined>}
 */
export const passExamine = pay_order_id =>
  fetch("/api", {
    api_name: "V1.Order.Examine.pass",
    pay_order_id: pay_order_id
  });
/**
 * 内采商品列表
 * @param pay_order_id
 * @returns {Promise<*|*|undefined>}
 */
export const getExamineItemList = pay_order_id =>
  fetch("/api", {
    api_name: "V1.Order.Examine.itemList",
    pay_order_id: pay_order_id
  });
/**
 * 我的收藏
 * @param params
 * @returns {Promise<*|*|undefined>}
 */
export const getUserCollectList = params =>
  fetch("/api", {
    api_name: "V1.User.User.getUserCollectList",
    ...params
  });
/**
 * 收藏商品/取消收藏
 * @param params
 * @returns {Promise<*|*|undefined>}
 */
export const addCollect = id =>
  fetch("/api", {
    api_name: "V1.User.User.addCollect",
    item_id: id
  });
//获取客服信息
export const getServiceConfig = name =>
  fetch("/api", {
    api_name: "V1.system.system.getConfig",
    name: name
  });
/**
 * 物资领用商品分类
 * @business_id商城id
 * @returns {Promise<*|*|undefined>}
 */
export const getGoodsCate = business_id =>
  fetch("/api/getGoodsCate", {
    api_name: "V1.User.Goods.getGoodsCate",
    business_id
  });
/**
 * 物资领用获取商品
 * @good_cate_id二级分类id
 * @returns {Promise<*|*|undefined>}
 */
export const getGoods = () =>
  fetch("/api/getGoods", {
    api_name: "V1.User.Goods.getGoods"
  });

/**
 * 物资领用申请提交
 * @params
 * @returns {Promise<*|*|undefined>}
 */
export const isSubmitGoodsOrder = params =>
  fetch("/api/submitGoodsOrder", {
    api_name: "V1.User.Goods.submitGoodsOrder",
    goods: JSON.stringify(params)
  });
/**
 * 物资明细
 * @params
 * @returns {Promise<*|*|undefined>}
 */
export const getGoodsList = params =>
  fetch("/api/getGoodsOrderList", {
    api_name: "V1.User.Goods.getGoodsOrderList",
    ...params
  });
//预算审核
export const postBudgetApply = (params) =>
fetch("/api", {
  api_name: "V1.Order.Examine.budgetApply",
  ...params
});
//获取预算审核
export const getBudgetApplyInfo = () =>
fetch("/api", {
  api_name: "V1.Order.Examine.budgetApplyInfo"
});

/**
 * 物资明细撤销
 * @params
 * @returns {Promise<*|*|undefined>}
 */
export const getCancelOrder = id =>
  fetch("/api/cancelOrder", {
    api_name: "V1.User.Goods.cancelOrder",
    id
  });

/**
 * 获取可赠送的积分或余额
 * @params
 * @returns {Promise<*|*|undefined>}
 */
export const getWealthInfo = params =>
  fetch("/api", {
    api_name: "V1.User.Give.getWealthInfo"
  });

/**
 * 转赠余额或积分
 * @params
 * @returns {Promise<*|*|undefined>}
 */
export const giveUserWealth = params =>
  fetch("/api", {
    api_name: "V1.User.Give.giveUserWealth",
    ...params
  });
/**
 * 大转盘奖品领取
 * @params
 * @returns {Promise<*|*|undefined>}
 */
export const receiveAward = params =>
fetch("/api", {
  api_name: "V1.item.item.receiveAward",
  ...params
});
/**
 * 大地保险签到
 * @params
 * @returns {Promise<*|*|undefined>}
 */
export const getSignPageData = () =>
fetch("/api", {
  api_name: "V1.User.User.getSignPageData"
});
/**
 * 大地保险兑换补签
 * @params
 * @returns {Promise<*|*|undefined>}
 */
 export const exchangeSignCard = () =>
 fetch("/api", {
   api_name: "V1.User.User.exchangeSignCard"
 });
 /**
 * 大地保险当日签到
 * @params
 * @returns {Promise<*|*|undefined>}
 */
export const directSign = () =>
fetch("/api", {
  api_name: "V1.User.User.directSign"
});
/**
 * 大地保险补签
 * @params
 * @returns {Promise<*|*|undefined>}
 */
export const supplementalSign = () =>
fetch("/api", {
  api_name: "V1.User.User.supplementalSign"
});
/**
 * 海元素抽奖
 * @params
 * @returns {Promise<*|*|undefined>}
 */
export const drawHaiyuansu = params =>
fetch("/api", {
  api_name: "V1.item.item.drawHaiyuansu",
  ...params
});
/**
 * 海元素抽奖记录
 * @params
 * @returns {Promise<*|*|undefined>}
 */
export const getDrawLogHaiyuansu = params =>
fetch("/api", {
  api_name: "V1.item.item.getDrawLogHaiyuansu",
  ...params
});
/**
 * 海元素抽奖信息
 * @params
 * @returns {Promise<*|*|undefined>}
 */
export const getActivityDetail = params =>
fetch("/api", {
  api_name: "V1.item.item.getActivityDetail",
  ...params
});

/**
 * 海元素抽奖獎品詳情
 * @params
 * @returns {Promise<*|*|undefined>}
 */
export const getDrawDetailHaiyuansu = params =>
fetch("/api", {
  api_name: "V1.item.item.getDrawDetailHaiyuansu",
  ...params
});

/**
 * 領取海元素抽奖獎品
 * @params
 * @returns {Promise<*|*|undefined>}
 */
export const receiveAwardHaiyuansu = params =>
fetch("/api", {
  api_name: "V1.item.item.receiveAwardHaiyuansu",
  ...params
});
/**
 * 创建组队队伍
 * @params
 * @returns {Promise<*|*|undefined>}
 */
export const createTeam = params =>
fetch("/api", {
  api_name: "V1.item.lotteryTeam.createTeam",
  ...params
});
/**
 * 我的队伍
 * @params
 * @returns {Promise<*|*|undefined>}
 */
export const getMyTeamInfo = params =>
fetch("/api", {
  api_name: "V1.item.lotteryTeam.getMyTeamInfo",
  ...params
});
/**
 * 领取奖品
 * @params
 * @returns {Promise<*|*|undefined>}
 */
export const receiveAwardTeam = params =>
fetch("/api", {
  api_name: "V1.item.item.receiveAwardTeam",
  ...params
});
/**
 * 参与组队
 * @params
 * @returns {Promise<*|*|undefined>}
 */
export const enterTeam = params =>
fetch("/api", {
  api_name: "V1.item.lotteryTeam.enterTeam",
  ...params
});
/**
 * 组队奖品详情接口
 * @params
 * @returns {Promise<*|*|undefined>}
 */
export const getTeamUserDetail = params =>
fetch("/api", {
  api_name: "V1.item.lotteryTeam.getTeamUserDetail",
  ...params
});
/**
 * 退出组队
 * @params
 * @returns {Promise<*|*|undefined>}
 */
export const exitsTeam = params =>
fetch("/api", {
  api_name: "V1.item.lotteryTeam.exitsTeam",
  ...params
});
/**
 * 分享组队信息
 * @params
 * @returns {Promise<*|*|undefined>}
 */
export const getTeamMember = params =>
fetch("/api", {
  api_name: "V1.item.lotteryTeam.getTeamMember",
  ...params
});
/**
 * 抽奖活动是否开启
 * @params
 * @returns {Promise<*|*|undefined>}
 */
export const getActivityStatus = params =>
fetch("/api", {
  api_name: "V1.item.item.getActivityStatus",
  ...params
});