/**
 * 配置编译环境和线上环境之间的切换
 *
 * baseUrl: 域名地址
 * routerMode: 路由模式
 * imgBaseUrl: 图片所在域名地址
 *
 */

let baseUrl = "";
const routerMode = "hash";
let imgBaseUrl = "";

/* if (process.env.NODE_ENV == "development") {
  // baseUrl = "http://wx.yqtb2b.com";
  baseUrl = "http://yiqitong.121.soft1024.com";
  imgBaseUrl = "";
} else if (process.env.NODE_ENV == "production") {
//   baseUrl = "http://wx.yqtb2b.com";
  baseUrl = "http://yiqitong.121.soft1024.com";
  imgBaseUrl = "";
} else {
  // 默认读取环境变量
  baseUrl = process.env.VUE_APP_BASE_API;
  imgBaseUrl = "";
} */

baseUrl = process.env.VUE_APP_BASE_API;

export {
  baseUrl,
  routerMode,
  imgBaseUrl
};