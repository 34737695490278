<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive && isRouterAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive && isRouterAlive" />
    <!-- 测试质量球 start -->
    <!-- <quality-ball :quality="quality" v-if="$route.path != '/question_feedback'"></quality-ball> -->
    <!-- 测试质量球 end -->
  </div>
</template>

<script>
import QualityBall from "@/components/qualityBall/QualityBall";
import { getColor, isAuth, login3, logout } from "@/services";
import { getUrlKey,getStore } from "@/utils/common";
import { tripartite, customAccount, terminal } from "@/utils/custom";
import { mapState, mapMutations } from "vuex";
import { jyLogin, bocLogin } from "@/services/user";

export default {
  name: "app",
  components: {
    QualityBall,
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      quality: "yellow",
      isRouterAlive: true,
      guangdaStatus: false, // 是否光大商场
      pointsTopic: false,
    };
  },
  computed: {
    ...mapState({
      tripartite_type: (state) => state.tripartite_type,
    }),
  },
  methods: {
    ...mapMutations(["getUser", "getSessionId"]),
    getParams() {
      let bid = getUrlKey("bid");

      if (bid) {
        this.isAuth(bid);
      }
    },
    async isAuth(bid) {
      if (window.localStorage.getItem("bid") !== bid && bid) {
        this.$store.commit("clearUserInfo");
        window.localStorage.clear();
        window.localStorage.setItem("bid", bid);
      }
      if (customAccount("VUE_APP_POINTS_TOPIC")) {
        this.pointsTopic = true;
        // console.log('12', this.pointsTopic)
      }
      this.guangdaStatus = tripartite("VUE_APP_GUANGDALOGIN");
      try {
        const res = await isAuth();
        if (res.code === 0) {
          if (!this.pointsTopic && !this.guangdaStatus) {
            this.$store.dispatch("getUserInfo");
          }
        }
      } catch (e) {}
    },
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
    async getCWInfo() {
      let res;
      console.log(!getStore("session_id"))
      if (this.tripartite_type === "VUE_APP_CHAOWEI") {
        let token = getUrlKey("token");
        if (!token &&  getStore("session_id") == null) {
          this.$router.push("/login");
        } else if(!!token){
          res = await login3({ chaowei_token: token });
        }
        if (res.code === 0) {
          const { PHPSESSID, user } = res.data;
          const show = res.data.show;
          if (show) {
            sessionStorage.setItem("show", show);
          } else {
            sessionStorage.setItem("show", "");
          }
          SID = PHPSESSID;
          this.getSessionId(PHPSESSID);
          this.$store.dispatch("getUserInfo");
          await this.getUser(user);
          try {
            const data = await this.$store.dispatch("getBusinessRate");
          } catch (e) {}
        } else if (res.code == 1) {
          const res = logout();
          if (res.code == 0) {
            sessionStorage.removeItem("show");
            localStorage.removeItem("userInfo");
            localStorage.removeItem("code");
            localStorage.removeItem("generalize_code");
            // localStorage.clear()
            sessionStorage.clear();
            this.$dialog.toast({
              mes: "登陆失效请重新登录",
              icon: "success",
            });
            localStorage.removeItem("has_input_password");
            this.$router.push("/login");
          }
        } else {
          alert(res.msg);
        }
      }
      return;
    },
    // 中行定制，引入cordova
    addCordova() {
      if (this.tripartite_type === "VUE_APP_ZHONGHANG") {
        const script = document.createElement("script");
        const pth = "https://ebsnew.boc.cn/bocphone/BocMerchant/"; // 生产环境

        // 外网环境直接引用生产地址即可，若需新增cordova方法，另行提供DMZ区地址
        if (terminal().iPhone && terminal().bocApp) {
          script.src = pth + "paylib/ios.js";
          document.getElementsByTagName("head")[0].appendChild(script);
        } else if (terminal().android && terminal().bocApp) {
          script.src = pth + "paylib/android.js";
          document.getElementsByTagName("head")[0].appendChild(script);
        } else {
          this.$dialog.toast({
            mes: "未识别到APP环境",
            timeout: 1500,
            icon: "error"
          });
        }
      }
    },

    // 中行用户数据
    async bocLogin(data) {
      try {
        const res = await bocLogin(data);
        console.log(res);
        if (res.code === 0) {
          const {PHPSESSID, user} = res.data;
          const show = res.data.show
          if (show) {
            sessionStorage.setItem('show', show)
          } else {
            sessionStorage.setItem('show', '')
          }
          SID = PHPSESSID;
          this.getSessionId(PHPSESSID);
          this.$store.dispatch("getUserInfo");
          await this.getUser(user);
          try {
            const data = await this.$store.dispatch('getBusinessRate');
            this.$dialog.loading.close();
          } catch (e) {}
        } else  {
          alert(res.msg)
        }
      } catch (e) {}
    },

    // 中行获取用户信息
    async bankChinaLogin() {
      let $this = this;
      if (this.tripartite_type === "VUE_APP_ZHONGHANG" && terminal().bocApp) {
        this.$dialog.loading.open('加载中...');
        // 中行登录获取用户信息
        document.addEventListener('deviceready', function (){
          // “yes”登录;“no”未登录
          window.c_plugins.merchantBridge.getBocCustomerAllInfo(function (data) {
            if(data.cipherText){
              $this.bocLogin(data.cipherText)
            }else{
              this.$dialog.toast({
                mes: "未获取到用户信息",
                timeout: 1500,
                icon: "error"
              });
            }
          })
        })
      }
    },

    // 江阴登录
    async jyLogin() {
      if (this.tripartite_type === "VUE_APP_JY") {
        let digest = getUrlKey("digest");
        let plain = getUrlKey("plain");
        if (digest && plain) {
          let res = await jyLogin(digest, plain);
          if (res.code === 0) {
            const { PHPSESSID, user } = res.data;
            const show = res.data.show;
            if (show) {
              sessionStorage.setItem("show", show);
            } else {
              sessionStorage.setItem("show", "");
            }
            SID = PHPSESSID;
            this.getSessionId(PHPSESSID);
            this.$store.dispatch("getUserInfo");
            await this.getUser(user);
            try {
              const data = await this.$store.dispatch("getBusinessRate");
            } catch (e) {}
          } else {
            alert(res.msg);
          }
        }
      }
    },
  },
  created() {
    this.jyLogin();
  },
  async mounted() {
    //获取用户信息
    // if (!window.location.href.includes("blessingCard")) {
    await this.addCordova();
    await this.bankChinaLogin();
    this.getParams();
    await this.$store.dispatch("getBusinessRate");
    await this.$store.dispatch("getThemeColor");
    // }
    //获取超威项目用户信息
    this.getCWInfo();
  },
};
</script>

<style lang="less">
#app {
  height: 100%;
  -webkit-overflow-scrolling: touch;
}

:root {
  --main-color: #6c57ff;
}
</style>
