/*
 * @Author: zwb
 * @Date: 2019-05-23
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2019-08-15 13:45:57
 */
import fetch from "@/utils/fetch";

//// 登录注册模块
export const login = (mobile, password, jpush_reg_id = "1") =>
  fetch("/api", {
    api_name: "lepu.User.login",
    mobile: mobile,
    password: password,
    jpush_reg_id: jpush_reg_id
  });
/**
 * 获取图形验证码
 */
export const getImgCode = () =>
  fetch("/api", {
    api_name: "V1.service.captchaApi.index"
  });
/**
 *
 * @param code
 */
export const verifyPicCode = code =>
  fetch("/api", {
    api_name: "lepu.User.verifyPicCode",
    code: code
  });
/**
 * 获取短信验证码
 * @param mobile
 */
export const sendVerifyCode = mobile =>
  fetch("/api", {
    api_name: "lepu.User.sendVerifyCode",
    mobile: mobile
  });
/**
 *
 * @param mobile
 * @param verify_code
 */
export const checkVerifyCodeValid = (mobile, verify_code) =>
  fetch("/api", {
    api_name: "lepu.User.checkVerifyCodeValid",
    mobile: mobile,
    verify_code: verify_code
  });
/**
 * 找回密码
 * @param mobile
 * @param new_password
 * @param type
 * @param verify_code
 */
export const findPasswordBySms = (
  mobile,
  new_password,
  type = "",
  verify_code = ""
) =>
  fetch("/api", {
    api_name: "lepu.User.findPasswordBySms",
    mobile: mobile,
    new_password: new_password,
    type: type,
    verify_code: verify_code
  });

/**
 * 退出登录
 */
export const logout = () =>
  fetch("/api", {
    api_name: "lepu.User.logout"
  });

/**
 * 获取配置项
 */
export const getB3Config = () =>
  fetch("/api", {
    api_name: "V1.index.index.b3Config"
  });

//获取appid
export const getAppId = () =>
  fetch("/api", {
    api_name: "V1.User.index.getAppid"
  });

//获取登录方式配置
export const getLoginConfig = () =>
  fetch("/api", {
    api_name: "V1.system.system.getLoginConfig"
  });

//授权登录结果
export const weChatLogin = code =>
  fetch("/api", {
    api_name: "V1.User.index.weChatLogin",
    code: code
  });

//授权登录后短信验证
export const sendSms = mobile =>
  fetch("/api", {
    api_name: "V1.User.index.sendSms",
    mobile: mobile
  });

//授权后绑定登录
export const bindLogin = (mobile, code, password, nickname) =>
  fetch("/api", {
    api_name: "V1.User.index.bindingMobile",
    mobile: mobile,
    code: code,
    password: password,
    nickname: nickname
  });

//授权后绑定登录
export const bindingWxHaiYuanSu = (password, loginViaUsername) =>
  fetch("/api", {
    api_name: "V1.User.index.bindingWxHaiYuanSu",
    password: password,
    username: loginViaUsername
  });

//修改密码
export const changePassword = (old_password, password) =>
  fetch("/api", {
    api_name: "V1.User.Index.changePassword",
    old_password: old_password,
    password: password
  });

//饭卡修改密码
export const changePwd = (mobile, old_password, password, comId) =>
  fetch("/api", {
    api_name: "V1.yunkatong.user.changePwd",
    comId: comId, //793
    mobile,
    pwd: old_password,
    newpwd: password
  });

//解绑微信
export const unbindWx = () =>
  fetch("/api", {
    api_name: "V1.User.Index.unbindWx"
  });
//兑换卡发送短信验证码
export const sendCardTicket = mobile =>
  fetch("/api", {
    api_name: "V1.User.UserCardTicket.sendCode",
    mobile: mobile
  });
/**
 * 福卡获取
 */
export const getfukaCode = id =>
  fetch("/api", {
    api_name: "V1.User.UserCardTicket.getYunPic",
    id: id,
    sid: 1
  });
/**
 * 中行登录接口
 * @param cipherText
 */
export const bocLogin = cipherText =>
  fetch("/api", {
    api_name: "V1.User.Index.bocLogin",
    cipherText: cipherText
  });

/**
 * 江阴登录接口
 * @param cipherText
 */
export const jyLogin = (digest, plain) =>
  fetch("/api", {
    api_name: "V1.User.Index.jyLogin",
    digest: digest,
    plain: plain
  });
/**
 *
 *
 *
 * @param code
 */
/**
 * 获取下单公告
 */
export const getOrderNotice = () =>
  fetch("/api", {
    api_name: "V1.article.article.getOrderNotice"
  });
/**
 *
 * @param code
 */
