const CardMall = () =>
  import(/* webpackChunkName: "card-module" */ "./views/cardMall/Index.vue");
const ExchangeRules = () =>
  import(
    /* webpackChunkName: "card-module" */ "./views/cardMall/ExchangeRules.vue"
  );
const CheckOrder = () =>
  import(
    /* webpackChunkName: "card-module" */ "./views/cardMall/CheckOrder.vue"
  );
const CardOrderDetails = () =>
  import(
    /* webpackChunkName: "card-module" */ "./views/cardMall/OrderDetails.vue"
  );
const Exchange = () =>
  import(/* webpackChunkName: "card-module" */ "./views/cardMall/Exchange.vue");
const ExchangeSuccess = () =>
  import(
    /* webpackChunkName: "card-module" */ "./views/cardMall/ExchangeSuccess.vue"
  );
const CmGoodsDetail = () =>
  import(
    "./views/cardMall/CmGoodsDetail.vue"
  )

const cardMallRouters = [
  // 卡册商城
  {
    path: "/card_mall",
    name: "card_mall",
    component: CardMall,
    meta: {
      title: "卡册商城"
    }
  },
  // 卡册商城-兑换规则
  {
    path: "/exchange_rules",
    name: "exchange_rules",
    component: ExchangeRules,
    meta: {
      title: "兑换规则"
    }
  },
  // 卡册商城-查询订单
  {
    path: "/check_order",
    name: "check_order",
    component: CheckOrder,
    meta: {
      title: "查询订单"
    }
  },
  // 卡册商城-查询订单详情
  {
    path: "/check_order_details",
    name: "check_order_details",
    component: CardOrderDetails,
    meta: {
      title: "查询订单"
    }
  },
  // 卡册商城-兑换
  {
    path: "/card_exchange",
    name: "card_exchange",
    component: Exchange,
    meta: {
      title: "兑换"
    }
  },
  // 卡册商城-兑换成功
  {
    path: "/exchange_success",
    name: "exchange_success",
    component: ExchangeSuccess,
    meta: {
      title: "兑换成功"
    }
  },
  //卡册商城-详情页
  {
    path: "/card_goods_details",
    name: "card_goods_details",
    component: CmGoodsDetail,
    meta: {
      title: "卡册商品详情"
    }
  },
  {
    path: "/sale-list",
    name: "sale-list",
    component: resolve => require(["@/views/order/saleList.vue"], resolve),
    meta: {
      title: "服务类型"
    }
  },
  {
    path: "/apply-sale",
    name: "apply-sale",
    component: resolve => require(["@/views/order/applySale.vue"], resolve),
    meta: {
      title: "退款申请"
    }
  },
  {
    path: "/apply-success",
    name: "apply-success",
    component: resolve => require(["@/views/order/applySuccess.vue"], resolve),
    meta: {
      title: "申请成功"
    }
  }
];

export default cardMallRouters;
