import { formatDate } from "../utils/common";
import { ossProcess } from "../utils/alioss";

const filters = {
  formatDate(time) {
    var date = new Date(Number(time) * 1000);
    return formatDate(date, "yyyy-MM-dd");
  },
  formatDateTime(time) {
    var date = new Date(Number(time) * 1000);
    return formatDate(date, "yyyy-MM-dd hh:mm:ss");
  },
  formatDateStd(time) {
    var date = new Date(Number(time) * 1000);
    return formatDate(date, "yyyy/MM/dd hh:mm:ss");
  },
  formatDateStdCh(time) {
    var date = new Date(Number(time) * 1000);
    return formatDate(date, "yyyy年MM月dd日 hh:mm:ss");
  },
  formatDateStdC(time) {
    var date = new Date(Number(time) * 1000);
    return formatDate(date, "yyyy年MM月dd日");
  },
  formatDateStdTime(time) {
    var date = new Date(Number(time) * 1000);
    return formatDate(date, "yyyy/MM/dd");
  },
  formatDateTimeHm(time) {
    var date = new Date(Number(time) * 1000);
    return formatDate(date, "yyyy-MM-dd hh:mm");
  },
  formatHm(time) {
    var date = new Date(Number(time) * 1000);
    return formatDate(date, "hh:mm");
  },
  formatDataHm(time) {
    var date = new Date(Number(time) * 1000);
    return formatDate(date, "hh'mm''");
  },
  formatDateMD(time) {
    var date = new Date(Number(time) * 1000);
    return formatDate(date, "MM月dd日");
  },
  formatDateMDS(time) {
    var date = new Date(Number(time) * 1000);
    return formatDate(date, "MM.dd");
  },
  formatDateMDHM(time) {
    var date = new Date(Number(time) * 1000);
    return formatDate(date, "MM-dd hh:mm");
  },
  formatHour(time) {
    var date = new Date(Number(time) * 1000);
    return formatDate(date, "hh");
  },
  hideMobile(mobile) {
    var result = "";
    if (mobile) {
      result =
        // mobile.substring(0, 3) + "****" + mobile.substring(mobile.length - 4);
        mobile;
    }
    return result;
  },
  setHttps(url) {
    if (url) {
      const head = url.substr(0, 5);
      if (head === "https") {
        return url;
      } else {
        const newUrl = `https${url.substring(4)}`;
        return newUrl;
      }
    } else {
      return url;
    }
  },
  ossProcess(url, style) {
    return ossProcess(url, style)
  },
};

export default filters;
