import VueRouter from "vue-router";
import { orderRouter } from "./orderRouter.js";
import cardMallRouter from "./cardMallRouter";
import { seaRouter } from "./seaRouter.js";
import { tuobenRouter } from "./tobenLiJianjin";

const Login = () =>
  import(/* webpackChunkName: "login-module" */ "./views/login/Index.vue");
const Binding = () =>
  import(/* webpackChunkName: "login-module" */ "./views/login/Binding.vue");
const ForgetPassword = () =>
  import(
    /* webpackChunkName: "login-module" */
    "./views/login/ForgetPassword.vue"
  );
const SetPassword = () =>
  import(
    /* webpackChunkName: "login-module" */
    "./views/login/SetPassword.vue"
  );
const FkSetPassword = () =>
  import(
    /* webpackChunkName: "login-module" */
    "./views/login/FkSetPassword.vue"
  );
const Register = () =>
  import(/* webpackChunkName: "login-module" */ "./views/register/Index.vue");
const Generalize = () =>
  import(/* webpackChunkName: "login-module" */ "./views/generalize/Index.vue");
const RegisterSetPassword = () =>
  import(
    /* webpackChunkName: "login-module" */
    "./views/register/SetPassword.vue"
  );
const Home = () =>
  import(/* webpackChunkName: "home-module" */ "./views/index/Index.vue");
const Search = () =>
  import(/* webpackChunkName: "home-module" */ "./views/index/Search.vue");
const SearchJp = () =>
  import(/* webpackChunkName: "home-module" */ "./views/searchjp/Index.vue");
const ItemCommentList = () =>
  import(
    /* webpackChunkName: "home-module" */
    "./views/index/item/ItemComment.vue"
  );
const CommentDetails = () =>
  import(
    /* webpackChunkName: "home-module" */
    "./views/index/item/CommentDetails.vue"
  );
const CouponCenter = () =>
  import(
    /* webpackChunkName: "home-module" */
    "./views/index/CouponCenter.vue"
  );
const AnnouncementList = () =>
  import(
    /* webpackChunkName: "home-module" */
    "./views/index/announcement/List.vue"
  );
const AnnouncementDetails = () =>
  import(
    /* webpackChunkName: "home-module" */
    "./views/index/announcement/Details.vue"
  );
const SecondsKill = () =>
  import(/* webpackChunkName: "home-module" */ "./views/index/SecondsKill.vue");
const GroupBuyIndex = () =>
  import(/* webpackChunkName: "home-module" */ "./views/index/group/Index.vue");
const GroupBuying = () =>
  import(
    /* webpackChunkName: "home-module" */
    "./views/index/group/GroupBuying.vue"
  );
const SpecialTopic = () =>
  import(
    /* webpackChunkName: "home-module" */
    "./views/index/SpecialTopic.vue"
  );
const qualityBall = () =>
  import("./components/qualityBall/QuestionFeedback.vue");
const ErrorPage = () => import("./views/error/Index.vue");

export default new VueRouter({
  routes: [
    {
      path: "/",
      redirect: "/home",
      meta: {
        status: true,
      },
    },
    {
      path: "/auth_info",
      name: "AuthInfo",
      component: () => import("./views/index/authInfo.vue"),
      meta: {
        title: "个人中心",
      },
    },
    {
      path: "/404",
      name: "404",
      component: () => import("./views/error/404.vue"),
      meta: {
        title: "404",
        status: true,
      },
    },
    {
      path: "/gotov2",
      name: "gotov2",
      component: () => import("./views/index/gotov2.vue"),
      meta: {
        title: "跳转提醒",
        status: true,
      },
    },
    {
      path: "/kunshan404",
      name: "kunshan404",
      component: () => import("./views/error/kunshan404.vue"),
      meta: {
        title: "404",
        status: true,
      },
    },
    {
      path: "/login",
      name: "login",
      component: Login,
      meta: {
        title: "登录",
        status: true,
      },
    },
    {
      path: "/tabenLogin",
      name: "tabenLogin",
      component: () => import("./views/login/TabenLogin.vue"),
      meta: {
        title: "登录",
        status: true,
      },
    },
    {
      path: "/binding",
      name: "binding",
      component: Binding,
      meta: {
        title: "绑定手机号",
      },
    },
    {
      path: "/binding_phone",
      name: "binding_phone",
      component: (resolve) =>
        require(["@/views/register/BindingPhone.vue"], resolve),
      meta: {
        title: "绑定手机号",
      },
    },
    {
      path: "/fk_login",
      name: "fk_login",
      component: (resolve) => require(["@/views/fkLogin/index.vue"], resolve),
      meta: {
        title: "登录",
        status: true,
      },
    },
    {
      path: "/forget_password",
      name: "forget_password",
      component: ForgetPassword,
      meta: {
        title: "找回密码",
      },
    },
    {
      path: "/set_password",
      name: "set_password",
      component: SetPassword,
      meta: {
        title: "设置新密码",
      },
    },
    {
      path: "/fk_set_password",
      name: "fk_set_password",
      component: FkSetPassword,
      meta: {
        title: "设置新密码",
      },
    },
    {
      path: "/register",
      name: "register",
      component: Register,
      meta: {
        title: "注册账号",
      },
    },
    {
      path: "/generalize",
      name: "generalize",
      component: Generalize,
      meta: {
        title: "推广注册",
      },
    },
    {
      path: "/register_set_password",
      name: "register_set_password",
      component: RegisterSetPassword,
      meta: {
        title: "设置账户密码",
      },
    },
    {
      path: "/home",
      name: "home",
      component: Home,
      meta: {
        title: "首页",
        keepAlive: true,
        status: true,
      },
    },
    {
      path: "/search",
      name: "search",
      component: Search,
      meta: {
        title: "搜索",
        keepAlive: true,
        status: true,
      },
    },
    {
      path: "/search_jp",
      name: "search_jp",
      component: SearchJp,
      meta: {
        title: "搜索",
        keepAlive: true,
        status: true,
      },
    },
    {
      path: "/comment_list",
      name: "comment_list",
      component: ItemCommentList,
      meta: {
        title: "商品评价",
      },
    },
    {
      path: "/comment_details",
      name: "comment_details",
      component: CommentDetails,
      meta: {
        title: "查看评价",
      },
    },
    {
      path: "/coupon_center",
      name: "coupon_center",
      component: CouponCenter,
      meta: {
        title: "领券中心",
      },
    },
    {
      path: "/announcement_list",
      name: "announcement_list",
      component: AnnouncementList,
      meta: {
        title: "商城公告",
      },
    },
    {
      path: "/announcement_details",
      name: "announcement_details",
      component: AnnouncementDetails,
      meta: {
        title: "公告详情",
      },
    },
    {
      path: "/seconds_kill",
      name: "seconds_kill",
      component: SecondsKill,
      meta: {
        title: "限时秒杀",
      },
    },
    {
      path: "/group_buy",
      name: "group_buy",
      component: GroupBuyIndex,
      meta: {
        title: "超值团购",
      },
    },
    {
      path: "/group_buying",
      name: "group_buying",
      component: GroupBuying,
      meta: {
        title: "参加团购",
      },
    },
    {
      path: "/special_topic",
      name: "special_topic",
      component: SpecialTopic,
      meta: {
        title: "专区",
        keepAlive: true,
        status: true,
      },
    },
    {
      path: "/shopping_cart",
      name: "shopping_cart",
      component: (resolve) =>
        require(["@/views/shoppingCart/index.vue"], resolve),
      meta: {
        title: "购物车",
        keepAlive: true,
      },
    },
    {
      path: "/submit_order",
      name: "submit_order",
      component: (resolve) =>
        require(["@/views/submitOrder/SubmitOrder.vue"], resolve),
      meta: {
        title: "订单确认",
      },
    },
    {
      path: "/order_note",
      name: "order_note",
      component: (resolve) =>
        require(["@/views/submitOrder/orderNote.vue"], resolve),
      meta: {
        title: "订单备注",
      },
    },
    {
      path: "/pay_waitting",
      name: "pay_waitting",
      component: (resolve) =>
        require(["@/views/submitOrder/payWaitting.vue"], resolve),
      meta: {
        title: "支付中",
      },
    },
    {
      path: "/pay_success",
      name: "pay_success",
      component: (resolve) =>
        require(["@/views/submitOrder/success.vue"], resolve),
      meta: {
        title: "支付成功",
      },
    },
    {
      path: "/my",
      name: "my",
      component: (resolve) => require(["@/views/my/index.vue"], resolve),
      meta: {
        title: "我的",
        keepAlive: true,
      },
    },
    {
      path: "/special_my",
      name: "special_my",
      component: (resolve) => require(["@/views/my/specialIndex.vue"], resolve),
      meta: {
        title: "我的",
        keepAlive: true,
      },
    },
    {
      path: "/integral_list",
      name: "integral_list",
      component: (resolve) =>
        require(["@/views/my/integral/IntegralList.vue"], resolve),
      meta: {
        title: "积分",
      },
    },
    {
      path: "/integral_list_sign",
      name: "integral_list_sign",
      component: (resolve) =>
        require(["@/views/my/integral/IntegralListSign.vue"], resolve),
      meta: {
        title: "积分签到",
      },
    },
    {
      path: "/integral_rules",
      name: "integral_rules",
      component: (resolve) =>
        require(["@/views/my/integral/IntegralRules.vue"], resolve),
      meta: {
        title: "积分规则",
      },
    },
    {
      path: "/approval_detail",
      name: "approval_detail",
      component: (resolve) =>
        require(["@/views/my/purchaseApproval/ApprovalDetail.vue"], resolve),
      meta: {
        title: "审批详情",
      },
    },
    {
      path: "/purchasing_list",
      name: "purchasing_list",
      component: (resolve) =>
        require(["@/views/my/purchaseApproval/PurchasingList.vue"], resolve),
      meta: {
        title: "采购商品",
      },
    },
    {
      path: "/approval_list",
      name: "approval_list",
      component: (resolve) =>
        require(["@/views/my/purchaseApproval/ApprovalList.vue"], resolve),
      meta: {
        title: "采购审批",
      },
    },
    {
      path: "/refuse_reason",
      name: "refuse_reason",
      component: (resolve) =>
        require(["@/views/my/purchaseApproval/RefuseReason.vue"], resolve),
      meta: {
        title: "拒绝理由",
      },
    },
    {
      path: "/account",
      name: "account",
      component: (resolve) =>
        require(["@/views/my/account/MyAccount.vue"], resolve),
      meta: {
        title: "我的账户",
      },
    },
    {
      path: "/recharge",
      name: "recharge",
      component: (resolve) =>
        require(["@/views/my/account/recharge.vue"], resolve),
      meta: {
        title: "充值",
      },
    },
    {
      path: "/recharge_success",
      name: "recharge_success",
      component: (resolve) =>
        require(["@/views/my/account/success.vue"], resolve),
      meta: {
        title: "充值成功",
      },
    },
    {
      path: "/exchange",
      name: "exchange",
      component: (resolve) =>
        require(["@/views/my/account/exchange.vue"], resolve),
      meta: {
        title: "兑换",
      },
    },
    {
      path: "/presented",
      name: "presented",
      component: (resolve) =>
        require(["@/views/my/account/presented.vue"], resolve),
      meta: {
        title: "赠送",
      },
    },
    {
      path: "/exchange_result",
      name: "exchange_result",
      component: (resolve) =>
        require(["@/views/my/account/ExchangeType.vue"], resolve),
      meta: {
        title: "兑换",
      },
    },
    {
      path: "/personal_info",
      name: "personal_info",
      component: (resolve) =>
        require(["@/views/my/setting/PersonalInfo.vue"], resolve),
      meta: {
        title: "个人信息",
      },
    },
    {
      path: "/address",
      name: "address",
      component: (resolve) =>
        require(["@/views/my/address/AddressList.vue"], resolve),
      meta: {
        title: "收货地址",
      },
    },
    {
      path: "/new_address",
      name: "new_address",
      component: (resolve) =>
        require(["@/views/my/address/NewAddress.vue"], resolve),
      meta: {
        title: "新增地址",
      },
    },
    {
      path: "/collection",
      name: "collection",
      component: (resolve) =>
        require(["@/views/my/collection/CollectionList.vue"], resolve),
      meta: {
        title: "我的收藏",
      },
    },
    {
      path: "/goods_reserve",
      name: "goods_reserve",
      component: (resolve) =>
        require(["@/views/my/goods/GoodsReserve.vue"], resolve),
      meta: {
        title: "物资领用",
      },
    },
    {
      path: "/reserve_details",
      name: "reserve_details",
      component: (resolve) =>
        require(["@/views/my/goods/ReserveDetails.vue"], resolve),
      meta: {
        title: "领用明细",
      },
    },
    {
      path: "/approval",
      name: "approval",
      component: (resolve) =>
        require(["@/views/my/budgetApproval/budgetApproval.vue"], resolve),
      meta: {
        title: "审核预算",
      },
    },
    {
      path: "/coupons",
      name: "coupons",
      component: (resolve) =>
        require(["@/views/my/coupons/CouponsList.vue"], resolve),
      meta: {
        title: "我的优惠券",
      },
    },
    {
      path: "/lucky_wheel",
      name: "lucky_wheel",
      component: (resolve) =>
        require(["@/views/my/luckyWheel/index.vue"], resolve),
      meta: {
        title: "",
      },
    },
    {
      path: "/raffle_prize",
      name: "raffle_prize",
      component: (resolve) =>
        require(["@/views/my/luckyWheel/RafflePrize.vue"], resolve),
      meta: {
        title: "我的奖品",
      },
    },
    {
      path: "/setting",
      name: "setting",
      component: (resolve) =>
        require(["@/views/my/setting/setting.vue"], resolve),
      meta: {
        title: "设置",
      },
    },
    {
      path: "/verify_phone",
      name: "verify_phone",
      component: (resolve) =>
        require(["@/views/my/setting/changePayPwd/VerifyPhone.vue"], resolve),
      meta: {
        title: "验证手机号码",
      },
    },
    {
      path: "/change_phone",
      name: "change_phone",
      component: (resolve) =>
        require(["@/views/my/setting/changePayPwd/change_phone.vue"], resolve),
      meta: {
        title: "换绑手机号",
      },
    },
    {
      path: "/change_pwd",
      name: "change_pwd",
      component: (resolve) =>
        require(["@/views/my/setting/changePayPwd/PayPwd.vue"], resolve),
      meta: {
        title: "支付密码",
        roles: true,
      },
    },
    //修改成功
    {
      path: "/pwd_success",
      name: "pwd_success",
      component: (resolve) =>
        require(["@/views/my/setting/changePayPwd/success.vue"], resolve),
      meta: {
        title: "支付密码",
      },
    },
    {
      path: "/feedback",
      name: "feedback",
      component: (resolve) =>
        require(["@/views/my/setting/feedback.vue"], resolve),
      meta: {
        title: "意见反馈",
      },
    },
    {
      path: "/help_center",
      name: "help_center",
      component: (resolve) =>
        require(["@/views/my/setting/HelpCenter.vue"], resolve),
      meta: {
        title: "帮助中心",
      },
    },
    {
      path: "/article",
      name: "article",
      component: (resolve) =>
        require(["@/views/my/setting/Article.vue"], resolve),
      meta: {
        title: "帮助中心",
      },
    },
    {
      path: "/category",
      name: "category",
      component: (resolve) =>
        require(["@/views/category/category.vue"], resolve),
      meta: {
        title: "分类",
        status: true,
      },
    },
    {
      path: "/goods-detail",
      name: "goods-detail",
      component: (resolve) =>
        require(["@/views/goods/goodsDetail.vue"], resolve),
      meta: {
        title: "商品详情",
        status: true,
      },
    },
    {
      path: "/blessingCard/:id",
      name: "blessingCard",
      component: (resolve) => require(["@/views/fukaSms/index.vue"], resolve),
      meta: {
        title: "福卡",
      },
    },
    // 质量球反馈页面
    {
      path: "/question_feedback",
      name: "question_feedback",
      component: qualityBall,
      meta: {
        title: "问题反馈",
      },
    },
    ...orderRouter,
    ...cardMallRouter,
    {
      path: "*",
      name: "error",
      component: ErrorPage,
      meta: {
        title: "error",
      },
    },
    {
      path: "/yfk",
      name: "yfk",
      component: (resolve) => require(["@/views/message/yfk.vue"], resolve),
      meta: {
        title: "云福卡",
      },
    },
    {
      path: "/yufuka",
      name: "yufuka",
      component: (resolve) => require(["@/views/message/yufuka.vue"], resolve),
      meta: {
        title: "云福卡",
      },
    },
    {
      path: "/recode",
      name: "recode",
      component: (resolve) =>
        require(["@/views/my/exchangeRecode/recode.vue"], resolve),
      meta: {
        title: "兑换记录",
      },
    },
    {
      path: "/exchange_detail",
      name: "exchange_detail",
      component: (resolve) =>
        require([
          "@/views/my/exchangeRecode/component/exchangeDetail.vue",
        ], resolve),
      meta: {
        title: "兑换详情",
      },
    },
    {
      path: "/transit_page",
      name: "transit_page",
      component: (resolve) =>
        require(["@/components/common/transitPage.vue"], resolve),
      meta: {
        title: "跳转确认",
      },
    },
    {
      path: "/zhMnZl",
      name: "zhMnZl",
      component: (resolve) => require(["@/views/cardMall/zhMnZl.vue"], resolve),
      meta: {
        title: "爱暖华政，上海加油！",
      },
    },
    {
      path: "/activityMn",
      name: "activityMn",
      component: (resolve) =>
        require(["@/views/activity/activityMn.vue"], resolve),
      meta: {
        title: "蒙牛",
      },
    },
    ...seaRouter,
    ...tuobenRouter,
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      if (from.meta.keepAlive) {
        from.meta.savedPosition = document.body.scrollTop;
      }
      return {
        x: 0,
        y: to.meta.savedPosition || 0,
      };
    }
  },
});
