/*
 * @Author: zwb
 * @Date: 2019-06-06
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2019-08-20 21:05:56
 */
import { getAliOssConfigInfo } from "@/services/my";

const OSS = require("ali-oss");

export async function client() {
  try {
    const res = await getAliOssConfigInfo();
    if (res.code === 0) {
      const data = res.data.data;
      const client = new OSS({
        region: data.region,
        accessKeyId: data.access_key_id,
        accessKeySecret: data.access_key_secret,
        bucket: data.bucket, //要存储的目录名
        stsToken: data.sts_token,
        secure: true
      });
      return client;
    }
  } catch (err) {
    console.log(err);
  }
}

const processImageRe =
  /src\.yqtb2b\.com|yiqitong\.oss-cn-hangzhou\.aliyuncs\.com|src\.yqtbut\.com|yqtsrc\.oss-cn-hangzhou\.aliyuncs\.com/;

const processStyleList = [
  "style/w_60_h_auto",
  "style/w_120_h_auto",
  "style/w_240_h_auto",
  "style/w_480_h_auto",
  "style/w_720_h_auto",
  "style/w_960_h_auto",
  "style/w_1200_h_auto",
]

export const ossProcess = (url, style) => {
  if (
    processImageRe.test(url) &&
    url.indexOf("?") == -1 &&
    processStyleList.includes(style)
  ) {
    url = url + "?x-oss-process=" + style;
  }

  return url;
};
