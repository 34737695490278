var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c(
        "div",
        { attrs: { id: "quality-ball" } },
        [
          _c("router-link", { attrs: { to: "/question_feedback" } }, [
            _c("div", { staticClass: "ball", class: _vm.quality }),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }