import { baseUrl } from "./env";
import qs from "qs";
import { Toast } from "vue-yrui/dist/lib.rem/dialog";
import { JsonSort } from "@/utils/ksort";
import router from "../router";
import store from '../store';
import { getUrlKey, removeStore } from "./common";
import { tripartite, isfk, customAccount, zjgInteraction } from "./custom";

const card_book_id = getUrlKey("card_book_id");


export default async (url = "", data = {}, type = "POST", method = "fetch") => {
  let api_arr = data.api_name.split(".");
  let name = api_arr.slice(-1);
  type = type.toUpperCase();
  url = baseUrl + url + "/" + name;
  if (data.sid == 1) {
    // data.PHPSESSID = "da760e70a57e9468ae37f2f04a6c3284";
  } else {
    data.PHPSESSID = SID; //不需要sid接口 传一个sid:false 的参数
  }

  // data.bid='321548b7caa9c19b0f1bb2cf1fba76c4'

  // data.bid = '05f210cdeda893a8c11c270aa919e1f1'
  // data.bid = "e42bf51b929f10e80f08ba442bea7edc"; // 顾佳俊测试商城
  // data.bid = "ae60f02baf6938bd3e7252113170b58e"; // 内采
  // data.bid = "7d0aace44d39ba42089703fcb905afd0"; // 内采
  // data.bid = "imthpn"; // 积分
  // data.bid = '98c725047626ece2f7f9ff583ba6b846'; // 卡册商城
  // data.bid = '588d0aca3271a7779caf6f0b20ad31b9'; // 卡册商城
    if (card_book_id) {
    data.card_book_id = card_book_id;
  }

  if (type == "GET") {
    let dataStr = ""; //数据拼接字符串
    Object.keys(data).forEach(key => {
      dataStr += key + "=" + data[key] + "&";
    });

    if (dataStr !== "") {
      dataStr = dataStr.substr(0, dataStr.lastIndexOf("&"));
      url = url + "?" + dataStr;
    }
  }

  if (window.fetch && method == "fetch") {
    let headers = {};
    if (type === "POST") {
      headers = {
        Accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8"
      };
    } else if (type === "GET") {
      headers = {
        Accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json;charset=utf-8"
      };
    }
    let requestConfig = {
      // credentials: 'include',
      method: type,
      headers: headers,
      mode: "cors",
      cache: "force-cache"
    };

    let apiName = null;
    if (type == "POST") {
      const my_data = JsonSort(data);
      Object.defineProperty(requestConfig, "body", {
        value: qs.stringify(data)
      });
      apiName = my_data.api_name;
    }

    try {
      const response = await fetch(url, requestConfig);
      const responseJson = await response.json();

      if (responseJson.code == 101) {
				removeStore("userInfo");
				store.state.userInfo = {};

				// 张家港唤起登录
				if (store.state.tripartite_type === 'VUE_APP_ZJG') {
					zjgInteraction();
					return false;
				}
				if (tripartite('VUE_APP_TABEN')) {
					router.replace("tabenLogin");
				} else if (isfk()) {
					router.replace("fk_login");
				} else if (customAccount('VUE_APP_SEA_LOTTERY')) {
					router.replace('seaIndex')
				} else if (store.state.tripartite_type === 'VUE_APP_ZHONGHANG') {
					window.c_plugins.merchantBridge.continueAfterLogin(function () {
						// 需再获取一遍客户信息
						// 跳转前如点击支付，则调起支付控件；
						// 跳转前如点击交易记录，则跳转交易记录；
						// 跳转前如点击优惠券，则跳转优惠券
					}, function (err) {
						alert(err.message || err || '网络错误，请检查网络连接');
					}, {})
				} else {
					router.replace("login");
				}
      } else if (responseJson.code != 0 && responseJson.msg) {
        if (
          apiName === "V1.User.index.weChatLogin" &&
          responseJson.msg === "请先绑定手机号"
        ) {
          return;
        } else {
          apiName === "V1.Order.Order.createOrder" && responseJson.code != -2
            ? Toast({
                mes: responseJson.msg
              })
            : null;
        }
      }
      if(responseJson.code == 1001){
        Toast({
          mes: responseJson.msg
        })
      }
      return responseJson;
    } catch (error) {
      throw new Error(error);
    }
  } else {
    console.log(
      "Your smarty phone may not support for fetch API temporarily; use original XHR instead!!!"
    );
    return new Promise((resolve, reject) => {
      let requestObj;
      if (window.XMLHttpRequest) {
        requestObj = new XMLHttpRequest();
      } else {
        requestObj = new ActiveXObject();
      }

      let sendData = "";
      if (type == "POST") {
        const my_data = JsonSort(data);
        sendData = qs.stringify(data);
      }

      try {
        requestObj.open(type, url, true);
        requestObj.setRequestHeader("Accept", "application/json");
        requestObj.setRequestHeader("X-Requested-With", "XMLHttpRequest");
        requestObj.setRequestHeader("Content-type", "application/x-www-form-urlencoded;charset=utf-8");
        requestObj.send(sendData);

        requestObj.onreadystatechange = () => {
          if (requestObj.readyState == 4) {
            if (requestObj.status == 200) {
              let obj = requestObj.response;
              if (typeof obj !== "object") {
                obj = JSON.parse(obj);
              }
              if (obj.code == 101) {
								removeStore("userInfo");
								store.state.userInfo = {};

								// 张家港唤起登录
								if (store.state.tripartite_type === 'VUE_APP_ZJG') {
									zjgInteraction();
									return false;
								}

                if (tripartite('VUE_APP_TABEN')) {
                  router.replace("tabenLogin");
                } else if (isfk()) {
                  router.replace("fk_login");
                } else if (customAccount('VUE_APP_SEA_LOTTERY')) {
                  router.replace('seaIndex')
                } else if (store.state.tripartite_type === 'VUE_APP_ZHONGHANG') {
									window.c_plugins.merchantBridge.continueAfterLogin(function () {
										// 需再获取一遍客户信息
										// 跳转前如点击支付，则调起支付控件；
										// 跳转前如点击交易记录，则跳转交易记录；
										// 跳转前如点击优惠券，则跳转优惠券
									}, function (err) {
										alert(err.message || err || '网络错误，请检查网络连接');
									}, {})
								} else {
                  router.replace("login");
                }
              } else if (obj.code != 0 && obj.msg) {
                Toast({
                  mes: obj.msg
                });
              }
              resolve(obj);
            } else {
              reject(requestObj);
            }
          }
        };
      } catch (error) {
        throw new Error(error);
      }
    });
  }
};
