export const tuoben = [
  {
    path: "/tuobenShopList",
    name: "tuobenShopList",
    component: resolve =>
      require(["@/views/tbreducedPayment/shopList"], resolve),
    meta: {
      title: "首页商品列表"
    }
  },
  {
    path: "/tuobenIndex",
    name: "tuobenIndex",
    component: resolve =>
      require(["@/views/tbreducedPayment/tuobenIndex"], resolve),
    meta: {
      title: "拓本立减金"
    }
  },
  {
    path: "/tobenOrderList",
    name: "tobenOrderList",
    component: resolve =>
      require(["@/views/tbreducedPayment/orderList"], resolve),
    meta: {
      title: "订单列表"
    }
  }
];

export const tuobenRouter = [...tuoben];
